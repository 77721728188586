{
    "1": [
        "A",
        "B",
        "C",
        "D",
        "G",
        "I",
        "M",
        "N",
        "S",
        "V",
        "W",
        "X"
    ],
    "2": [
        "AI",
        "AK",
        "AL",
        "AN",
        "AS",
        "AT",
        "BE",
        "BY",
        "DO",
        "DR",
        "EM",
        "ET",
        "GO",
        "HE",
        "HP",
        "IF",
        "IN",
        "IS",
        "IT",
        "LA",
        "ME",
        "MM",
        "MP",
        "MY",
        "NE",
        "NO",
        "NV",
        "OF",
        "ON",
        "OR",
        "PC",
        "PF",
        "SO",
        "TE",
        "TH",
        "TL",
        "TM",
        "TO",
        "TV",
        "UM",
        "UP",
        "US",
        "WE",
        "YD"
    ],
    "3": [
        "ACT",
        "ADD",
        "AGE",
        "AGO",
        "AID",
        "AIR",
        "ALL",
        "AND",
        "ANY",
        "ARE",
        "ARM",
        "ART",
        "ASK",
        "ASP",
        "ATE",
        "AXE",
        "BAD",
        "BAG",
        "BAR",
        "BED",
        "BID",
        "BIG",
        "BIT",
        "BOB",
        "BOX",
        "BOY",
        "BTW",
        "BUT",
        "BUY",
        "CAN",
        "COW",
        "CRY",
        "CUB",
        "CUP",
        "CUT",
        "DAY",
        "DEN",
        "DID",
        "DIE",
        "DIM",
        "DNA",
        "DOC",
        "DON",
        "DRY",
        "DUE",
        "DUG",
        "EAT",
        "EGO",
        "END",
        "ETC",
        "EYE",
        "FAR",
        "FED",
        "FEE",
        "FEV",
        "FEW",
        "FIT",
        "FIX",
        "FOR",
        "FUN",
        "GAS",
        "GET",
        "GOD",
        "GOT",
        "GUN",
        "GUY",
        "HAD",
        "HAS",
        "HER",
        "HEY",
        "HIM",
        "HIS",
        "HIT",
        "HIV",
        "HOT",
        "HOW",
        "HUB",
        "IMI",
        "ITS",
        "JOB",
        "JOE",
        "KEY",
        "KID",
        "KIT",
        "LAB",
        "LAW",
        "LAY",
        "LED",
        "LEG",
        "LET",
        "LIE",
        "LIT",
        "LOT",
        "LOW",
        "MAD",
        "MAN",
        "MAP",
        "MAX",
        "MAY",
        "MEN",
        "MET",
        "MOW",
        "NEW",
        "NOR",
        "NOT",
        "NOW",
        "NPC",
        "OAK",
        "ODD",
        "OFF",
        "OIL",
        "OLD",
        "ONE",
        "ORE",
        "OUR",
        "OUT",
        "OWN",
        "PAY",
        "PCS",
        "PEE",
        "PIT",
        "POP",
        "PRY",
        "PSG",
        "PUT",
        "RAD",
        "RAN",
        "RAT",
        "RED",
        "REM",
        "RID",
        "RIG",
        "RIP",
        "ROB",
        "ROD",
        "ROY",
        "RUN",
        "SAD",
        "SAT",
        "SAW",
        "SAY",
        "SEA",
        "SEE",
        "SET",
        "SHE",
        "SIT",
        "SKY",
        "SLR",
        "SON",
        "SOY",
        "SUN",
        "TEN",
        "THE",
        "TIM",
        "TOO",
        "TOP",
        "TRY",
        "TWO",
        "USA",
        "USE",
        "UZI",
        "VAT",
        "VIA",
        "WAD",
        "WAR",
        "WAS",
        "WAY",
        "WEB",
        "WHO",
        "WHY",
        "WIN",
        "WON",
        "YET",
        "YOU"
    ],
    "4": [
        "ABLE",
        "ACTS",
        "ADDS",
        "AGED",
        "AGES",
        "ALLY",
        "ALSO",
        "AMMO",
        "ANDY",
        "AREA",
        "ARMS",
        "ARMY",
        "ARTS",
        "ASKS",
        "ATOP",
        "AWAY",
        "BACK",
        "BALL",
        "BAND",
        "BARE",
        "BASE",
        "BEAM",
        "BEAR",
        "BEAT",
        "BEDS",
        "BEEN",
        "BEGS",
        "BELT",
        "BEST",
        "BIDS",
        "BILL",
        "BITS",
        "BLAH",
        "BLOW",
        "BLUE",
        "BODY",
        "BOMB",
        "BONE",
        "BORE",
        "BORN",
        "BOSS",
        "BOTH",
        "BOYS",
        "BUMP",
        "BUNK",
        "BURN",
        "BUST",
        "BUSY",
        "CAGE",
        "CAIN",
        "CALL",
        "CAME",
        "CAMP",
        "CAPE",
        "CARE",
        "CASE",
        "CASH",
        "CAST",
        "CAVE",
        "CELL",
        "CHIP",
        "CITY",
        "CLAN",
        "CLAW",
        "CLUB",
        "CODE",
        "COLD",
        "COLT",
        "COMA",
        "COME",
        "COOL",
        "COPY",
        "CORE",
        "CORK",
        "CORN",
        "COST",
        "CRAP",
        "CULT",
        "CURE",
        "DAIS",
        "DAMN",
        "DANK",
        "DARK",
        "DAWN",
        "DAYS",
        "DEAD",
        "DEAL",
        "DEAN",
        "DECK",
        "DEED",
        "DEEM",
        "DEEP",
        "DENS",
        "DENY",
        "DESK",
        "DIED",
        "DIES",
        "DOES",
        "DOGS",
        "DOME",
        "DONE",
        "DOOM",
        "DOOR",
        "DOUR",
        "DOWN",
        "DRAG",
        "DRAW",
        "DRUG",
        "DUCK",
        "DUCT",
        "DUEL",
        "DUST",
        "DUTY",
        "EACH",
        "EARL",
        "EARN",
        "EAST",
        "EASY",
        "EDGE",
        "EGOS",
        "ELSE",
        "ENDS",
        "ERIC",
        "ERIK",
        "EVEN",
        "EVER",
        "EVIL",
        "EXIT",
        "EYES",
        "FACE",
        "FACT",
        "FAIL",
        "FALL",
        "FANG",
        "FARM",
        "FAST",
        "FEAR",
        "FEAT",
        "FEED",
        "FEEL",
        "FEET",
        "FELL",
        "FELT",
        "FEND",
        "FILE",
        "FIND",
        "FIRE",
        "FIVE",
        "FLAG",
        "FLAK",
        "FLAT",
        "FLED",
        "FLEE",
        "FOES",
        "FOLD",
        "FOND",
        "FOOD",
        "FOOT",
        "FORK",
        "FORM",
        "FOUL",
        "FOUR",
        "FRAG",
        "FREE",
        "FROM",
        "FUEL",
        "FULL",
        "FURS",
        "FURY",
        "FUSE",
        "GAIN",
        "GAME",
        "GANG",
        "GARL",
        "GATE",
        "GAVE",
        "GEAR",
        "GETS",
        "GIFT",
        "GIVE",
        "GLOW",
        "GOAL",
        "GODS",
        "GOES",
        "GOLD",
        "GOLF",
        "GONE",
        "GOOD",
        "GRAB",
        "GRAY",
        "GREW",
        "GREY",
        "GROW",
        "GUNS",
        "GURU",
        "GUTS",
        "HAIR",
        "HALF",
        "HALL",
        "HAND",
        "HANG",
        "HARD",
        "HARM",
        "HATE",
        "HAVE",
        "HAZY",
        "HEAD",
        "HEAR",
        "HEAT",
        "HELD",
        "HELP",
        "HERE",
        "HERO",
        "HIDE",
        "HIGH",
        "HIRE",
        "HITS",
        "HOLD",
        "HOLE",
        "HOLY",
        "HOME",
        "HOOK",
        "HOPE",
        "HOST",
        "HOUR",
        "HUGE",
        "HUNT",
        "HURT",
        "HUTS",
        "IDEA",
        "INFO",
        "INTO",
        "IRON",
        "ITEM",
        "JOBS",
        "JOIN",
        "JUKE",
        "JUMP",
        "JUNK",
        "JUST",
        "KEEP",
        "KEPT",
        "KEYS",
        "KHAN",
        "KICK",
        "KILL",
        "KIND",
        "KITS",
        "KNEW",
        "KNOW",
        "LABS",
        "LACK",
        "LAKE",
        "LAMP",
        "LAND",
        "LAST",
        "LATE",
        "LAWS",
        "LAYS",
        "LEAD",
        "LEFT",
        "LESS",
        "LETS",
        "LIES",
        "LIFE",
        "LIFT",
        "LIKE",
        "LINE",
        "LINK",
        "LISA",
        "LIVE",
        "LOAN",
        "LOCK",
        "LONE",
        "LONG",
        "LOOK",
        "LOOT",
        "LOSE",
        "LOSS",
        "LOST",
        "LOTS",
        "LOUD",
        "LOVE",
        "LUCK",
        "LUSH",
        "MACE",
        "MADE",
        "MAIN",
        "MAKE",
        "MALE",
        "MANY",
        "MASK",
        "MASS",
        "MATE",
        "MAUL",
        "MAZE",
        "MEAL",
        "MEAN",
        "MEAT",
        "MEET",
        "MENU",
        "MIND",
        "MISC",
        "MISS",
        "MOOD",
        "MOON",
        "MORE",
        "MOST",
        "MOVE",
        "MUCH",
        "MUST",
        "NAIL",
        "NAME",
        "NEAR",
        "NEED",
        "NEON",
        "NEWS",
        "NEXT",
        "NICE",
        "NINE",
        "NONE",
        "NOTE",
        "ODDS",
        "OILY",
        "ONCE",
        "ONES",
        "ONLY",
        "ONTO",
        "OPEN",
        "OPTS",
        "OVER",
        "OWED",
        "OWNS",
        "PACE",
        "PACK",
        "PAID",
        "PAIN",
        "PALE",
        "PANS",
        "PART",
        "PASS",
        "PAST",
        "PATH",
        "PAUL",
        "PAYS",
        "PENS",
        "PETS",
        "PICK",
        "PILE",
        "PITS",
        "PLAN",
        "PLAY",
        "PLOT",
        "PLUS",
        "POLE",
        "POOL",
        "POOR",
        "POST",
        "POTS",
        "POUR",
        "PRAY",
        "PROD",
        "PROP",
        "PURE",
        "PUSH",
        "PUTS",
        "RACE",
        "RAGS",
        "RAID",
        "RAIN",
        "RANK",
        "RARE",
        "RATE",
        "RATS",
        "READ",
        "REAL",
        "REAR",
        "RELY",
        "REST",
        "RING",
        "RISE",
        "ROAD",
        "ROAM",
        "ROCK",
        "ROLE",
        "ROLL",
        "ROOF",
        "ROOM",
        "ROPE",
        "ROSE",
        "RUDE",
        "RUDY",
        "RUIN",
        "RULE",
        "RUNS",
        "RUSH",
        "SAFE",
        "SAID",
        "SALT",
        "SAME",
        "SAND",
        "SASH",
        "SAVE",
        "SAYS",
        "SEAT",
        "SEED",
        "SEEK",
        "SEEM",
        "SEEN",
        "SEES",
        "SELF",
        "SELL",
        "SEND",
        "SENT",
        "SETS",
        "SEWN",
        "SHED",
        "SHIP",
        "SHOP",
        "SHOT",
        "SHOW",
        "SHUT",
        "SICK",
        "SIDE",
        "SIGN",
        "SINK",
        "SITS",
        "SIZE",
        "SKIN",
        "SLAP",
        "SLID",
        "SLIM",
        "SLIP",
        "SLOW",
        "SLUG",
        "SLUM",
        "SNOT",
        "SOAP",
        "SOIL",
        "SOLD",
        "SOME",
        "SONG",
        "SOON",
        "SORT",
        "SOUL",
        "SPIN",
        "SPOT",
        "STAR",
        "STAY",
        "STEP",
        "STIR",
        "STOP",
        "STUN",
        "SUCH",
        "SUNG",
        "SUNK",
        "SURE",
        "SWAP",
        "TAKE",
        "TALE",
        "TALK",
        "TALL",
        "TANK",
        "TARP",
        "TASK",
        "TEAM",
        "TEAR",
        "TECH",
        "TELL",
        "TEND",
        "TENT",
        "TERM",
        "TEST",
        "THAN",
        "THAT",
        "THEM",
        "THEN",
        "THEY",
        "THIN",
        "THIS",
        "THUG",
        "THUS",
        "TIDY",
        "TIES",
        "TILE",
        "TIME",
        "TINY",
        "TIRE",
        "TOLD",
        "TOLL",
        "TOOK",
        "TOOL",
        "TORE",
        "TOWN",
        "TRAP",
        "TRAY",
        "TREE",
        "TREK",
        "TRIP",
        "TRUE",
        "TURN",
        "TYPE",
        "UNIT",
        "UPON",
        "USED",
        "USES",
        "VAST",
        "VATS",
        "VERY",
        "VOTE",
        "WAIT",
        "WAKE",
        "WALK",
        "WALL",
        "WANE",
        "WANT",
        "WARD",
        "WARM",
        "WARN",
        "WARS",
        "WARY",
        "WAVE",
        "WAYS",
        "WEAK",
        "WEAR",
        "WEEK",
        "WELL",
        "WENT",
        "WERE",
        "WEST",
        "WHAT",
        "WHEN",
        "WHOM",
        "WIFE",
        "WILD",
        "WILL",
        "WIND",
        "WINS",
        "WIPE",
        "WIRE",
        "WISE",
        "WISH",
        "WISP",
        "WITH",
        "WOMP",
        "WOOD",
        "WORD",
        "WORK",
        "WORN",
        "YARD",
        "YEAH",
        "YEAR",
        "YELL",
        "YOUR",
        "ZONE"
    ],
    "5": [
        "ABOUT",
        "ABOVE",
        "ACTOR",
        "ADAPT",
        "ADDED",
        "ADEPT",
        "ADOBE",
        "AFTER",
        "AGAIN",
        "AGENT",
        "AGILE",
        "AGREE",
        "AHEAD",
        "ALARM",
        "ALERT",
        "ALIEN",
        "ALIKE",
        "ALIVE",
        "ALLEY",
        "ALLOW",
        "ALONE",
        "ALONG",
        "ALTER",
        "AMONG",
        "ANGRY",
        "ANGUS",
        "ANKLE",
        "APART",
        "APPLY",
        "APTLY",
        "AREAS",
        "ARENA",
        "ARMED",
        "ARMOR",
        "AROSE",
        "ASIDE",
        "ASKED",
        "AVOID",
        "AWAIT",
        "AWARE",
        "AWAYS",
        "BADLY",
        "BANDS",
        "BASED",
        "BASES",
        "BASIC",
        "BASIN",
        "BATON",
        "BEAST",
        "BEATS",
        "BEGAN",
        "BEGIN",
        "BEING",
        "BELOW",
        "BESET",
        "BETTY",
        "BLACK",
        "BLADE",
        "BLAST",
        "BLEND",
        "BLOCK",
        "BLOOD",
        "BLOWN",
        "BOARD",
        "BOMBS",
        "BONDS",
        "BONES",
        "BONUS",
        "BOOKS",
        "BOOST",
        "BOOTH",
        "BOOTS",
        "BOOTY",
        "BOOZE",
        "BORED",
        "BRAIN",
        "BRASS",
        "BREAK",
        "BRICK",
        "BRIEF",
        "BRING",
        "BRINK",
        "BROKE",
        "BROOM",
        "BRUNT",
        "BRUTE",
        "BUILD",
        "BUILT",
        "BULLY",
        "BUNCH",
        "BUTCH",
        "CACHE",
        "CAGES",
        "CALLS",
        "CANDY",
        "CARDS",
        "CARED",
        "CARES",
        "CARGO",
        "CARRY",
        "CASES",
        "CASTE",
        "CATCH",
        "CAUSE",
        "CAVES",
        "CELLS",
        "CHAIR",
        "CHAOS",
        "CHEAP",
        "CHEAT",
        "CHECK",
        "CHEST",
        "CHILD",
        "CHOSE",
        "CIVIL",
        "CLAIM",
        "CLANS",
        "CLASS",
        "CLAWS",
        "CLEAN",
        "CLEAR",
        "CLIMB",
        "CLIPS",
        "CLOCK",
        "CLOSE",
        "CLOTH",
        "CLOUD",
        "CLUBS",
        "CLUES",
        "CLUMP",
        "COATS",
        "COBRA",
        "CODES",
        "COLOR",
        "COMES",
        "COULD",
        "COVER",
        "CRAFT",
        "CRANE",
        "CRASH",
        "CRASS",
        "CRAZY",
        "CREED",
        "CRIED",
        "CRIME",
        "CROSS",
        "CRUDE",
        "CRUEL",
        "DAILY",
        "DAISY",
        "DARED",
        "DAREN",
        "DAZED",
        "DEATH",
        "DECAY",
        "DEEDS",
        "DEEMS",
        "DEITY",
        "DELAY",
        "DEMON",
        "DIARY",
        "DIRTY",
        "DISKS",
        "DOING",
        "DOORS",
        "DOZEN",
        "DRANK",
        "DRAWN",
        "DREAM",
        "DREGS",
        "DRESS",
        "DRIED",
        "DRINK",
        "DUNES",
        "DYING",
        "EAGER",
        "EAGLE",
        "EARLY",
        "EARTH",
        "ELDER",
        "ELITE",
        "EMPTY",
        "ENACT",
        "ENDED",
        "ENEMY",
        "ENTER",
        "ENTRY",
        "EQUAL",
        "ERUPT",
        "ETHAN",
        "EVENT",
        "EVERY",
        "EXCEL",
        "EXIST",
        "EXTRA",
        "FACES",
        "FAILS",
        "FAITH",
        "FALLS",
        "FALSE",
        "FARGO",
        "FARMS",
        "FAULT",
        "FAUST",
        "FAVOR",
        "FENCE",
        "FERAL",
        "FETCH",
        "FIBER",
        "FIELD",
        "FIFTH",
        "FIGHT",
        "FINAL",
        "FINDS",
        "FIRST",
        "FISTS",
        "FIXES",
        "FLAME",
        "FLARE",
        "FLASH",
        "FLASK",
        "FLESH",
        "FLOCK",
        "FLOOR",
        "FLUID",
        "FOODS",
        "FORCE",
        "FORTH",
        "FOUND",
        "FOYER",
        "FRAME",
        "FRANK",
        "FREED",
        "FREES",
        "FRIED",
        "FRIES",
        "FRONT",
        "FRUIT",
        "FURRY",
        "GAINS",
        "GAMES",
        "GANGS",
        "GATES",
        "GAUNT",
        "GEEKS",
        "GHOST",
        "GHOUL",
        "GIANT",
        "GIVEN",
        "GIVES",
        "GLASS",
        "GLEAM",
        "GLOBE",
        "GLORY",
        "GLOVE",
        "GOALS",
        "GOING",
        "GOODS",
        "GOONS",
        "GRADE",
        "GRASP",
        "GRASS",
        "GRAVE",
        "GREAT",
        "GREED",
        "GREEN",
        "GRIEF",
        "GROUP",
        "GROWN",
        "GROWS",
        "GUARD",
        "GUESS",
        "GUIDE",
        "GUIDO",
        "GURPS",
        "HALLS",
        "HANDS",
        "HANDY",
        "HAPPY",
        "HAREM",
        "HARSH",
        "HATCH",
        "HATES",
        "HAVEN",
        "HEADS",
        "HEALS",
        "HEARD",
        "HEARS",
        "HEART",
        "HEAVY",
        "HELPS",
        "HENCE",
        "HERBS",
        "HIDES",
        "HILLS",
        "HINTS",
        "HIRED",
        "HOLDS",
        "HOLES",
        "HOMES",
        "HONOR",
        "HOPED",
        "HOPES",
        "HORDE",
        "HOURS",
        "HOUSE",
        "HOWLS",
        "HUMAN",
        "HUMOR",
        "HYDES",
        "IDEAL",
        "IDEAS",
        "IMAGE",
        "INNER",
        "ITALY",
        "ITEMS",
        "JACOB",
        "JAMES",
        "JERRY",
        "JOINS",
        "JOKES",
        "JOLLY",
        "JONES",
        "KEEPS",
        "KHANS",
        "KICKS",
        "KILLS",
        "KINDA",
        "KINDS",
        "KNIFE",
        "KNOCK",
        "KNOWN",
        "KNOWS",
        "LANCE",
        "LANDS",
        "LARGE",
        "LASER",
        "LATER",
        "LAWNS",
        "LEADS",
        "LEARN",
        "LEAST",
        "LEAVE",
        "LEVEL",
        "LIGHT",
        "LIKES",
        "LIMIT",
        "LINED",
        "LINES",
        "LISTS",
        "LIVED",
        "LIVES",
        "LOADS",
        "LOCAL",
        "LOCKS",
        "LODGE",
        "LOGIC",
        "LOOKS",
        "LOOMS",
        "LOONY",
        "LOOSE",
        "LORDS",
        "LOSES",
        "LOVED",
        "LOVER",
        "LOVES",
        "LOWER",
        "LOYAL",
        "LUCKY",
        "LYING",
        "MACHO",
        "MAGIC",
        "MAJOR",
        "MAKES",
        "MALES",
        "MARCH",
        "MARKS",
        "MASKS",
        "MAYBE",
        "MAZES",
        "MEANS",
        "MEANT",
        "MEATS",
        "MEETS",
        "METAL",
        "METER",
        "MICRO",
        "MIDST",
        "MIGHT",
        "MILES",
        "MINDS",
        "MINES",
        "MINOR",
        "MODEL",
        "MONEY",
        "MONTH",
        "MOUTH",
        "MOVED",
        "MUSIC",
        "NAILS",
        "NAMED",
        "NAMES",
        "NASTY",
        "NEARS",
        "NEEDS",
        "NERVE",
        "NESTS",
        "NEVER",
        "NEWLY",
        "NIGHT",
        "NOISE",
        "NORTH",
        "NOTED",
        "NOTES",
        "NUKES",
        "OASIS",
        "OCCUR",
        "OFFER",
        "OFTEN",
        "OMEGA",
        "OPENS",
        "ORBIT",
        "ORDER",
        "OTHER",
        "OUTER",
        "OWNED",
        "OWNER",
        "PACKS",
        "PAGES",
        "PAINT",
        "PANEL",
        "PAPER",
        "PARTS",
        "PARTY",
        "PATHS",
        "PEACE",
        "PERIL",
        "PICKS",
        "PIECE",
        "PILED",
        "PIPES",
        "PLACE",
        "PLANS",
        "PLANT",
        "PLATE",
        "PLAYS",
        "PLOTS",
        "PLUSH",
        "POINT",
        "POSED",
        "POSTS",
        "POUCH",
        "POWER",
        "PRICE",
        "PRODS",
        "PRONE",
        "PROOF",
        "PROUD",
        "PROVE",
        "PULLS",
        "PUNKS",
        "QUEST",
        "QUICK",
        "QUIET",
        "QUITE",
        "RACES",
        "RADIO",
        "RAGED",
        "RAIDS",
        "RAISE",
        "RANGE",
        "RANKS",
        "RATES",
        "RAZED",
        "RAZOR",
        "REACH",
        "READY",
        "REFER",
        "RELAY",
        "RELIC",
        "REPEL",
        "RESTS",
        "REVEL",
        "RIGHT",
        "RIGID",
        "RIVAL",
        "RIVER",
        "ROBES",
        "ROBOT",
        "ROCKS",
        "ROCKY",
        "ROLLS",
        "ROMAN",
        "ROOMS",
        "ROUGH",
        "ROUND",
        "RUINS",
        "RULED",
        "RULER",
        "RULES",
        "RUMOR",
        "SADLY",
        "SAFER",
        "SAMBA",
        "SANDS",
        "SAVED",
        "SAVES",
        "SCALE",
        "SCANT",
        "SCARF",
        "SCENE",
        "SCENT",
        "SCOPE",
        "SCOTT",
        "SCOUR",
        "SCOUT",
        "SCRAP",
        "SEALS",
        "SEEDS",
        "SEEDY",
        "SEEMS",
        "SEIZE",
        "SELLS",
        "SENDS",
        "SENSE",
        "SERVE",
        "SETUP",
        "SEVEN",
        "SEVER",
        "SEWER",
        "SHADY",
        "SHAPE",
        "SHAVE",
        "SHEER",
        "SHINY",
        "SHOCK",
        "SHOPS",
        "SHORT",
        "SHOTS",
        "SHOWN",
        "SHOWS",
        "SHRUG",
        "SIDES",
        "SIEGE",
        "SIGHT",
        "SIGNS",
        "SILKS",
        "SINCE",
        "SIXTY",
        "SIZES",
        "SKIES",
        "SKILL",
        "SKINS",
        "SKULL",
        "SLATE",
        "SLAVE",
        "SLEEP",
        "SLIDE",
        "SLING",
        "SLIPS",
        "SLITS",
        "SMALL",
        "SMILE",
        "SMOKE",
        "SNAKE",
        "SNARE",
        "SNEAK",
        "SNIFF",
        "SNUCK",
        "SOLAR",
        "SONIC",
        "SORTS",
        "SOUND",
        "SOUTH",
        "SPANS",
        "SPARE",
        "SPARK",
        "SPEAK",
        "SPEAR",
        "SPEED",
        "SPEND",
        "SPENT",
        "SPIED",
        "SPIES",
        "SPIKE",
        "SPILL",
        "SPLIT",
        "SPOKE",
        "SPOON",
        "SPRAY",
        "SPREE",
        "SQUAD",
        "STAFF",
        "STAKE",
        "STAND",
        "STARK",
        "STARS",
        "START",
        "STASH",
        "STATE",
        "STAYS",
        "STEAD",
        "STEAL",
        "STEEL",
        "STEPS",
        "STERN",
        "STEVE",
        "STILL",
        "STOCK",
        "STOLE",
        "STONE",
        "STOOL",
        "STOPS",
        "STORE",
        "STORM",
        "STORY",
        "STOUT",
        "STRAY",
        "STUCK",
        "STUDY",
        "STUFF",
        "STYLE",
        "SUITS",
        "SUPER",
        "SURGE",
        "SWAMP",
        "SWEAR",
        "SWEPT",
        "SWISS",
        "SWORD",
        "SWORE",
        "TABLE",
        "TAINT",
        "TAKEN",
        "TAKES",
        "TALKS",
        "TANDY",
        "TANKS",
        "TASKS",
        "TAUNT",
        "TEAMS",
        "TEETH",
        "TELLS",
        "TERMS",
        "TESTS",
        "TEXAS",
        "TEXTS",
        "THANK",
        "THEIR",
        "THERE",
        "THESE",
        "THICK",
        "THIEF",
        "THING",
        "THINK",
        "THIRD",
        "THOSE",
        "THREE",
        "THREW",
        "THROW",
        "THUGS",
        "TIGHT",
        "TILES",
        "TIMER",
        "TIMES",
        "TIRED",
        "TIRES",
        "TODAY",
        "TOMES",
        "TORCH",
        "TOUGH",
        "TOWEL",
        "TOWER",
        "TOWNS",
        "TOXIC",
        "TRACE",
        "TRACK",
        "TRACT",
        "TRADE",
        "TRAIL",
        "TRAIN",
        "TRAPS",
        "TRASH",
        "TREES",
        "TRIAL",
        "TRIBE",
        "TRICK",
        "TRIED",
        "TRIES",
        "TRIPS",
        "TRITE",
        "TROOP",
        "TRULY",
        "TRUST",
        "TRUTH",
        "TURNS",
        "TWANG",
        "TWIGS",
        "TWIST",
        "TYPED",
        "TYPES",
        "UNDER",
        "UNITE",
        "UNITS",
        "UNITY",
        "UNTIL",
        "UPSET",
        "USING",
        "USUAL",
        "VALUE",
        "VAULT",
        "VENOM",
        "VENTS",
        "VIEWS",
        "VIPER",
        "VIRAL",
        "VIRUS",
        "VITAL",
        "VOICE",
        "WAGON",
        "WAITS",
        "WAKES",
        "WALKS",
        "WALLS",
        "WANTS",
        "WARES",
        "WARNS",
        "WASTE",
        "WATCH",
        "WATER",
        "WAVES",
        "WEARS",
        "WEEKS",
        "WEIRD",
        "WHEEL",
        "WHERE",
        "WHICH",
        "WHILE",
        "WHITE",
        "WHOLE",
        "WHOSE",
        "WIELD",
        "WINDS",
        "WIPED",
        "WIRES",
        "WISER",
        "WITCH",
        "WOMAN",
        "WOMEN",
        "WOODS",
        "WORDS",
        "WORKS",
        "WORLD",
        "WORRY",
        "WORSE",
        "WORTH",
        "WOULD",
        "WRATH",
        "WRIST",
        "WRITE",
        "WRONG",
        "WROTE",
        "YEARS",
        "YIELD",
        "YOUNG"
    ],
    "6": [
        "ABOUND",
        "ABSENT",
        "ACCEPT",
        "ACCESS",
        "ACROSS",
        "ACTING",
        "ACTUAL",
        "ADMIRE",
        "ADVICE",
        "AFFORD",
        "AFRAID",
        "AFRESH",
        "AGREED",
        "AGREES",
        "ALARMS",
        "ALBEIT",
        "ALLIES",
        "ALLOWS",
        "ALMOST",
        "ALWAYS",
        "AMBUSH",
        "AMOUNT",
        "ANGELS",
        "ANIMAL",
        "ANSWER",
        "ANYONE",
        "ANYWAY",
        "APPEAR",
        "ARGUED",
        "ARMIES",
        "ARMORY",
        "AROUND",
        "ARRIVE",
        "ARROWS",
        "ATOMIC",
        "ATRIUM",
        "ATTACK",
        "ATTAIN",
        "ATTEND",
        "AWHILE",
        "BACKED",
        "BARBED",
        "BARELY",
        "BARLEY",
        "BARNEY",
        "BARTER",
        "BASSET",
        "BATTLE",
        "BEATEN",
        "BECAME",
        "BECOME",
        "BEETLE",
        "BEFELL",
        "BEFORE",
        "BEGINS",
        "BEHIND",
        "BELIEF",
        "BELIES",
        "BELONG",
        "BETTER",
        "BEYOND",
        "BIGGER",
        "BITTEN",
        "BLADES",
        "BLAMED",
        "BLASTS",
        "BLOCKS",
        "BLOODY",
        "BODIES",
        "BOOTED",
        "BOOTHS",
        "BORDER",
        "BORROW",
        "BOTHER",
        "BOTTLE",
        "BOTTOM",
        "BOUGHT",
        "BOWELS",
        "BREAKS",
        "BREWER",
        "BRICKS",
        "BRIDGE",
        "BRINGS",
        "BROKEN",
        "BRUTAL",
        "BULLET",
        "BUNDLE",
        "BUNKER",
        "BURIED",
        "BURNED",
        "BURROW",
        "BUTTON",
        "CABBOT",
        "CABLES",
        "CALLED",
        "CANNOT",
        "CARING",
        "CARPET",
        "CARVED",
        "CASINO",
        "CATTLE",
        "CAUGHT",
        "CAUSED",
        "CAUSES",
        "CAVERN",
        "CELLAR",
        "CENTER",
        "CHAIRS",
        "CHANCE",
        "CHANGE",
        "CHARGE",
        "CHASED",
        "CHOICE",
        "CHOOSE",
        "CHOSEN",
        "CHURCH",
        "CINDER",
        "CIRCLE",
        "CIRCUS",
        "CITIES",
        "CLEVER",
        "CLOSED",
        "CLOSER",
        "CLOSES",
        "CLOSET",
        "COARSE",
        "COATED",
        "COCOON",
        "COLORS",
        "COMBAT",
        "COMING",
        "COMMIT",
        "COMMON",
        "COOLLY",
        "COPIES",
        "CORNER",
        "COUPLE",
        "COURSE",
        "COVERS",
        "COYOTE",
        "CRATER",
        "CRATES",
        "CRAVEN",
        "CRAZED",
        "CREATE",
        "CRIMES",
        "CRISIS",
        "CROWDS",
        "CURFEW",
        "CUSTOM",
        "DAGGER",
        "DAMAGE",
        "DANGER",
        "DAPPER",
        "DARING",
        "DEADLY",
        "DEALER",
        "DEATHS",
        "DEBATE",
        "DECIDE",
        "DECKER",
        "DEEPER",
        "DEFEAT",
        "DEFEND",
        "DELVED",
        "DEMEAN",
        "DENIES",
        "DEPART",
        "DEPEND",
        "DESERT",
        "DESIGN",
        "DETAIL",
        "DETECT",
        "DEVICE",
        "DEVOID",
        "DEVOTE",
        "DEVOUT",
        "DHARMA",
        "DIFFER",
        "DIPPED",
        "DIRECT",
        "DIVINE",
        "DOMAIN",
        "DONNED",
        "DOZENS",
        "DRAWER",
        "DREAMS",
        "DRENCH",
        "DRIVEN",
        "DRIVER",
        "DRIVES",
        "DRONES",
        "DUBBED",
        "DURING",
        "DUTIES",
        "EARNED",
        "EASIER",
        "EASILY",
        "EFFECT",
        "EITHER",
        "ELDERS",
        "EMPIRE",
        "ENDING",
        "ENDURE",
        "ENERGY",
        "ENGAGE",
        "ENLIST",
        "ENOUGH",
        "ENRAGE",
        "ENSUED",
        "ENSURE",
        "ENTERS",
        "ENTIRE",
        "ERASED",
        "ERUPTS",
        "ESCAPE",
        "ESCORT",
        "EVENTS",
        "EXCEPT",
        "EXILED",
        "EXISTS",
        "EXODUS",
        "EXOTIC",
        "EXPECT",
        "EXPOSE",
        "FACIAL",
        "FACING",
        "FAILED",
        "FAIRLY",
        "FALCON",
        "FALLEN",
        "FAMILY",
        "FAMOUS",
        "FATHER",
        "FAULTY",
        "FEARED",
        "FELLOW",
        "FENDED",
        "FIELDS",
        "FIERCE",
        "FIGHTS",
        "FIGURE",
        "FILLED",
        "FILTHY",
        "FINELY",
        "FIRING",
        "FIRMLY",
        "FLOORS",
        "FLUENT",
        "FOLDED",
        "FOLLOW",
        "FONDLY",
        "FORCED",
        "FORCES",
        "FORGES",
        "FORGET",
        "FORGOT",
        "FORMAL",
        "FORMED",
        "FORMER",
        "FOUGHT",
        "FOURTH",
        "FRAMES",
        "FRIDAY",
        "FRIEND",
        "FRUITS",
        "FUTURE",
        "GAINED",
        "GARDEN",
        "GATHER",
        "GEIGER",
        "GENTLY",
        "GHOULS",
        "GIVING",
        "GLADLY",
        "GLANCE",
        "GOLDEN",
        "GOVERN",
        "GRATES",
        "GREETS",
        "GROUND",
        "GROUPS",
        "GROWTH",
        "GRUNGY",
        "GRUNTS",
        "GUARDS",
        "GUIDES",
        "HACKER",
        "HAMMER",
        "HANDED",
        "HANDLE",
        "HAPPEN",
        "HARDER",
        "HARDLY",
        "HARRIS",
        "HATRED",
        "HAULED",
        "HAVING",
        "HEALED",
        "HEARTS",
        "HEATED",
        "HEEDED",
        "HELMET",
        "HELPED",
        "HERALD",
        "HIDDEN",
        "HIGHER",
        "HIGHLY",
        "HONEST",
        "HORROR",
        "HOUSES",
        "HUMANS",
        "HUNGRY",
        "HUNTED",
        "IDEALS",
        "IGUANA",
        "IMMUNE",
        "INDEED",
        "INDIAN",
        "INFORM",
        "INSANE",
        "INSECT",
        "INSIDE",
        "INSULT",
        "INTACT",
        "INTENT",
        "IRVINE",
        "ISSUED",
        "ITSELF",
        "JACKAL",
        "JACKET",
        "JAMMED",
        "JUDGED",
        "JUNGLE",
        "JUSTIN",
        "KARATE",
        "KATANA",
        "KEYPAD",
        "KIDNAP",
        "KILLED",
        "KINDLY",
        "KISSED",
        "KNIGHT",
        "KNIVES",
        "KNOCKS",
        "LADDER",
        "LASTED",
        "LATELY",
        "LATEST",
        "LAUNCH",
        "LAYERS",
        "LEADER",
        "LEAKED",
        "LEARNS",
        "LEAVES",
        "LEGEND",
        "LEGION",
        "LENGTH",
        "LETTER",
        "LEVELS",
        "LIFTED",
        "LIGHTS",
        "LIKELY",
        "LIMPED",
        "LIQUID",
        "LISTED",
        "LISTEN",
        "LITTLE",
        "LIVING",
        "LOCALS",
        "LOCKED",
        "LONGER",
        "LOOKED",
        "LOOTED",
        "LOSING",
        "LOVELY",
        "LOWEST",
        "LUXURY",
        "MAINLY",
        "MAKERS",
        "MAKING",
        "MANAGE",
        "MANNED",
        "MARBLE",
        "MARKED",
        "MARKET",
        "MASTER",
        "MATTER",
        "MEAGER",
        "MELTED",
        "MEMBER",
        "MEMORY",
        "MENIAL",
        "MENTAL",
        "MIDDLE",
        "MIGHTY",
        "MINDED",
        "MINUTE",
        "MIRROR",
        "MISERS",
        "MODELS",
        "MODERN",
        "MODULE",
        "MOMENT",
        "MONGOL",
        "MONTHS",
        "MORALS",
        "MORBID",
        "MOSTLY",
        "MOTHER",
        "MOTION",
        "MOVIES",
        "MOVING",
        "MUGGED",
        "MURDER",
        "MUSCLE",
        "MUSEUM",
        "MUTANT",
        "MUTATE",
        "MUTTER",
        "MUTUAL",
        "NAILED",
        "NARROW",
        "NATHAN",
        "NATION",
        "NATURE",
        "NEARBY",
        "NEEDED",
        "NEURAL",
        "NEVADA",
        "NIGHTS",
        "NOBODY",
        "NOISES",
        "NOMADS",
        "NORMAL",
        "NOTICE",
        "NUMBED",
        "NUMBER",
        "OFFERS",
        "OFFICE",
        "OPENED",
        "OPENLY",
        "OPPOSE",
        "ORDERS",
        "ORNATE",
        "OTHERS",
        "OUSTED",
        "OUTFIT",
        "OWNERS",
        "OXYGEN",
        "PALACE",
        "PANELS",
        "PASSED",
        "PASSES",
        "PATROL",
        "PAYING",
        "PEOPLE",
        "PERIAN",
        "PERIOD",
        "PERISH",
        "PERSON",
        "PICKED",
        "PIECES",
        "PILLOW",
        "PIPBOY",
        "PISTOL",
        "PLACED",
        "PLACES",
        "PLANTS",
        "PLAQUE",
        "PLAYED",
        "PLAYER",
        "PLEADS",
        "PLEASE",
        "PLENTY",
        "POCKET",
        "POINTS",
        "POISED",
        "POISON",
        "POLICE",
        "POSTED",
        "POTION",
        "POWDER",
        "POWERS",
        "PRAISE",
        "PRAYER",
        "PREACH",
        "PREFER",
        "PRETTY",
        "PRICED",
        "PRIEST",
        "PRISON",
        "PROFIT",
        "PROPER",
        "PROVED",
        "PROVEN",
        "PRYING",
        "PSYCHE",
        "PSYCHO",
        "PUBLIC",
        "PULLED",
        "PYTHON",
        "QUESTS",
        "QUIRKS",
        "RACOON",
        "RACREE",
        "RADIOS",
        "RADIUS",
        "RAIDED",
        "RAIDER",
        "RAINED",
        "RAISED",
        "RANDOM",
        "RANGED",
        "RANGER",
        "RANGES",
        "RANSOM",
        "RARELY",
        "RATHER",
        "RATION",
        "RAVING",
        "REALLY",
        "REASON",
        "RECENT",
        "RECORD",
        "REFUSE",
        "REMAIN",
        "REMIND",
        "REMOTE",
        "REOPEN",
        "REPAIR",
        "REPORT",
        "RESCUE",
        "RESIDE",
        "RESULT",
        "RETURN",
        "REVEAL",
        "REVOLT",
        "REWARD",
        "RICHES",
        "RIFLES",
        "RIGGED",
        "RIPPED",
        "RIPPER",
        "RITUAL",
        "ROBOTS",
        "ROCKED",
        "ROCKET",
        "ROMARA",
        "ROUNDS",
        "ROUTES",
        "ROVING",
        "RUBBLE",
        "RUGRAT",
        "RUINED",
        "RULERS",
        "RULING",
        "RUMORS",
        "RUSTED",
        "SACRED",
        "SAFELY",
        "SAFETY",
        "SALTON",
        "SAVAGE",
        "SAYING",
        "SCARCE",
        "SCARED",
        "SCHOOL",
        "SCORCH",
        "SCREAM",
        "SCREEN",
        "SCRIBE",
        "SCYTHE",
        "SEALED",
        "SEARCH",
        "SECOND",
        "SECRET",
        "SEEING",
        "SEEMED",
        "SEIZED",
        "SELECT",
        "SENSED",
        "SERIES",
        "SERMON",
        "SERVES",
        "SETTLE",
        "SEWAGE",
        "SEWERS",
        "SHACKS",
        "SHADOW",
        "SHEETS",
        "SHELLS",
        "SHIELD",
        "SHINER",
        "SHOOTS",
        "SHOULD",
        "SHOUTS",
        "SHOVEL",
        "SHOWED",
        "SHOWER",
        "SHRINE",
        "SHRUBS",
        "SHRUGS",
        "SIGHTS",
        "SILVER",
        "SIMPLE",
        "SIMPLY",
        "SINGLE",
        "SIRENS",
        "SKILLS",
        "SKULLS",
        "SLAVES",
        "SLEDGE",
        "SLEEPS",
        "SLIDES",
        "SLIGHT",
        "SLOWLY",
        "SMOOTH",
        "SNACKS",
        "SNAKES",
        "SNEAKS",
        "SONICS",
        "SOUGHT",
        "SOUNDS",
        "SOURCE",
        "SPACED",
        "SPARED",
        "SPARSE",
        "SPEAKS",
        "SPEARS",
        "SPEECH",
        "SPENDS",
        "SPIKES",
        "SPIRAL",
        "SPIRES",
        "SPIRIT",
        "SPOILS",
        "SPOKES",
        "SPRAWL",
        "SPREAD",
        "SPRING",
        "SPRUNG",
        "SPYING",
        "SQUARE",
        "SQUEAL",
        "STABLE",
        "STAGES",
        "STAIRS",
        "STANDS",
        "STARTS",
        "STATED",
        "STATIC",
        "STATUE",
        "STATUS",
        "STAYED",
        "STEALS",
        "STOLEN",
        "STONES",
        "STORES",
        "STRAWS",
        "STREAM",
        "STREET",
        "STRIPS",
        "STRONG",
        "STRUCK",
        "STUFFS",
        "STUPID",
        "SUBTLE",
        "SUCKER",
        "SUDDEN",
        "SULLEN",
        "SUMMON",
        "SUPPLY",
        "SWINGS",
        "SWITCH",
        "SWIVEL",
        "SWORDS",
        "SYMBOL",
        "SYSTEM",
        "TABLES",
        "TAKING",
        "TARGET",
        "TASTES",
        "TAUGHT",
        "TAUNTS",
        "TAURUS",
        "TAVERN",
        "TEDIUM",
        "TEEVEE",
        "TEMPLE",
        "TESTED",
        "THANKS",
        "THEIRS",
        "THINGS",
        "THINKS",
        "THIRTY",
        "THOMAS",
        "THOUGH",
        "THRASH",
        "THREAT",
        "THRONE",
        "THROWN",
        "THROWS",
        "THWART",
        "TIMERS",
        "TISSUE",
        "TOILET",
        "TONGUE",
        "TOSSED",
        "TOWERS",
        "TRACES",
        "TRACKS",
        "TRADED",
        "TRADER",
        "TRANCE",
        "TRAVEL",
        "TRIBES",
        "TRICKS",
        "TROOPS",
        "TROPHY",
        "TRYING",
        "TUNNEL",
        "TURNED",
        "TWENTY",
        "TWISTS",
        "UNABLE",
        "UNDULY",
        "UNEASY",
        "UNLESS",
        "UNLIKE",
        "UPHOLD",
        "UPROAR",
        "UTMOST",
        "UTOPIA",
        "VALLEY",
        "VAULTS",
        "VECTOR",
        "VICTIM",
        "VIPERS",
        "VISAGE",
        "VISION",
        "VISITS",
        "VOICES",
        "WACKOS",
        "WAGONS",
        "WALKED",
        "WALLET",
        "WANDER",
        "WANTED",
        "WARNED",
        "WASHES",
        "WASTED",
        "WASTES",
        "WEAKEN",
        "WEAKER",
        "WEALTH",
        "WEAPON",
        "WICKED",
        "WILDLY",
        "WINTER",
        "WISHES",
        "WITHIN",
        "WIZARD",
        "WONDER",
        "WOODEN",
        "WORKED",
        "WORTHY",
        "WRITER",
        "YELLOW",
        "YIELDS"
    ],
    "7": [
        "ABILITY",
        "ACHIEVE",
        "ACQUIRE",
        "ACTIONS",
        "ACTRESS",
        "ADOPTED",
        "ADORNED",
        "ADVISES",
        "AFFRONT",
        "AGAINST",
        "AIRLOCK",
        "ALCOHOL",
        "ALLOWED",
        "ALREADY",
        "AMALGAM",
        "AMONGST",
        "AMOUNTS",
        "ANCIENT",
        "ANDROID",
        "ANGELIC",
        "ANGERED",
        "ANGUISH",
        "ANIMALS",
        "ANNOYED",
        "ANOTHER",
        "ANSWERS",
        "ANYTIME",
        "APPEARS",
        "ARMORED",
        "ARRIVAL",
        "ARRIVED",
        "ASHAMED",
        "ASSIGNS",
        "ASSUMED",
        "ATTACKS",
        "ATTEMPT",
        "ATTENDS",
        "AVERAGE",
        "AWESOME",
        "BANDITS",
        "BANNING",
        "BANSHEE",
        "BARRAGE",
        "BARRENS",
        "BARRIER",
        "BASTARD",
        "BASTION",
        "BATTLES",
        "BEATING",
        "BECAUSE",
        "BECOMES",
        "BEDROOM",
        "BEEPING",
        "BELIEFS",
        "BELIEVE",
        "BELONGS",
        "BENCHES",
        "BENEATH",
        "BENEFIT",
        "BESIDES",
        "BETWEEN",
        "BIGGEST",
        "BIGOTRY",
        "BIZARRE",
        "BLANKET",
        "BLASTED",
        "BLAZING",
        "BLESSED",
        "BLOWING",
        "BORROWS",
        "BOTTLES",
        "BRACERS",
        "BRIEFLY",
        "BROTHER",
        "BROUGHT",
        "BUILDER",
        "BUNDLED",
        "BURNING",
        "BURROWS",
        "CABINET",
        "CALIBER",
        "CALLING",
        "CAMPING",
        "CANCERS",
        "CANTEEN",
        "CANTINA",
        "CAPABLE",
        "CAPITOL",
        "CAPTAIN",
        "CAPTORS",
        "CAPTURE",
        "CARAVAN",
        "CAREFUL",
        "CARRIED",
        "CARRIER",
        "CARRIES",
        "CAUSING",
        "CAVERNS",
        "CEILING",
        "CENTRAL",
        "CERAMIC",
        "CERTAIN",
        "CHAINED",
        "CHAMBER",
        "CHANGED",
        "CHOOSES",
        "CIRCUIT",
        "CISTERN",
        "CITADEL",
        "CLAWING",
        "CLEANSE",
        "CLEARED",
        "CLEAVER",
        "CLIMATE",
        "CLOSELY",
        "CLOSEST",
        "CLOSING",
        "CLOTHES",
        "COCHISE",
        "COHORTS",
        "COLLECT",
        "COMMAND",
        "COMMITS",
        "COMPANY",
        "COMPASS",
        "COMPLEX",
        "CONCERN",
        "CONDUCT",
        "CONFESS",
        "CONFIRM",
        "CONQUER",
        "CONSIST",
        "CONSUME",
        "CONTACT",
        "CONTAIN",
        "CONTENT",
        "CONTEST",
        "CONTROL",
        "COOKERY",
        "COPYING",
        "CORNERS",
        "CORRALS",
        "COSTING",
        "COUNCIL",
        "COUNTER",
        "COUNTRY",
        "COUSINS",
        "COVERED",
        "COWARDS",
        "CRAFTED",
        "CRAZIES",
        "CREATED",
        "CREDITS",
        "CRIMSON",
        "CRIPPLE",
        "CROSSED",
        "CROWBAR",
        "CROWDED",
        "CRUCIAL",
        "CRUSADE",
        "CRUSHED",
        "CURIOUS",
        "CURRENT",
        "CUTTERS",
        "CUTTING",
        "CYBORGS",
        "DAGGERS",
        "DAMAGED",
        "DANCERS",
        "DANCING",
        "DANGERS",
        "DEALING",
        "DEATHLY",
        "DECADES",
        "DECIDED",
        "DECLARE",
        "DECLINE",
        "DECORUM",
        "DECREES",
        "DECRIED",
        "DECRIES",
        "DEFEATS",
        "DEFENSE",
        "DEMANDS",
        "DENYING",
        "DEPARTS",
        "DESERTS",
        "DESIRED",
        "DESIRES",
        "DESPAIR",
        "DESPITE",
        "DESTROY",
        "DETAILS",
        "DEVELOP",
        "DEVICES",
        "DEVIOUS",
        "DEVOLVE",
        "DISABLE",
        "DISBAND",
        "DISCUSS",
        "DIVIDED",
        "DOLLARS",
        "DOORWAY",
        "DRAGONS",
        "DRAINED",
        "DRESSED",
        "DRESSES",
        "DRIVING",
        "DROPPED",
        "DUNGEON",
        "DURABLE",
        "DUSTERS",
        "DWELLER",
        "DWINDLE",
        "EASIEST",
        "EFFECTS",
        "EFFORTS",
        "ELDERLY",
        "ELECTED",
        "ELEGANT",
        "ELEMENT",
        "EMBRACE",
        "EMERGED",
        "EMOTION",
        "EMPEROR",
        "ENABLES",
        "ENCASED",
        "ENCLAVE",
        "ENDINGS",
        "ENEMIES",
        "ENFORCE",
        "ENGLISH",
        "ENHANCE",
        "ENSLAVE",
        "ENSUING",
        "ERECTED",
        "ERRANDS",
        "ESCAPED",
        "ESCAPES",
        "ESCORTS",
        "ESSENCE",
        "EXACTLY",
        "EXCLAIM",
        "EXPANSE",
        "EXPECTS",
        "EXPLAIN",
        "EXPOSED",
        "EXPRESS",
        "EXTRACT",
        "EXTREME",
        "FACTION",
        "FAILURE",
        "FALLING",
        "FALLOUT",
        "FANATIC",
        "FARMING",
        "FARTHER",
        "FAVORED",
        "FEARING",
        "FEELING",
        "FENCING",
        "FERTILE",
        "FESTERS",
        "FIGHTER",
        "FILTERS",
        "FINALLY",
        "FINDING",
        "FIREARM",
        "FISHING",
        "FITTING",
        "FIZZLES",
        "FLOWERS",
        "FLOWING",
        "FOCUSED",
        "FOLLOWS",
        "FORBADE",
        "FOREVER",
        "FORTIFY",
        "FOUNDED",
        "FREEDOM",
        "FREIGHT",
        "FRIENDS",
        "FURTHER",
        "GABBING",
        "GAINING",
        "GANGERS",
        "GARBAGE",
        "GATEWAY",
        "GENERAL",
        "GENGHIS",
        "GENUINE",
        "GETTING",
        "GHENGIS",
        "GHOSTLY",
        "GODLIKE",
        "GOGGLES",
        "GRADUAL",
        "GRANITE",
        "GRANTED",
        "GREATLY",
        "GREENED",
        "GREETED",
        "GRENADE",
        "GROCERY",
        "GROOMED",
        "GROUPED",
        "GROWING",
        "GUARDED",
        "GUMMING",
        "GUNFIRE",
        "HALBERD",
        "HALLWAY",
        "HAMMERS",
        "HANDGUN",
        "HANDLES",
        "HANGING",
        "HANGOUT",
        "HAPPENS",
        "HARMFUL",
        "HARNESS",
        "HATCHET",
        "HAZARDS",
        "HEADING",
        "HEADSET",
        "HEALING",
        "HEALTHY",
        "HEARING",
        "HEARTED",
        "HEAVENS",
        "HEAVILY",
        "HEIGHTS",
        "HELPFUL",
        "HELPING",
        "HERSELF",
        "HIDEOUT",
        "HIMSELF",
        "HISSING",
        "HISTORY",
        "HOLDING",
        "HOLSTER",
        "HORIZON",
        "HOSTILE",
        "HOUSING",
        "HOWEVER",
        "HOWLING",
        "HUNDRED",
        "HUNTERS",
        "HUNTING",
        "HURTING",
        "HUSBAND",
        "ILLNESS",
        "IMAGINE",
        "IMPLIES",
        "IMPROVE",
        "INCLUDE",
        "INGROWN",
        "INHUMAN",
        "INITIAL",
        "INQUIRE",
        "INSISTS",
        "INSTANT",
        "INSTEAD",
        "INSTORE",
        "INSULTS",
        "INTENSE",
        "INVADED",
        "INVOLVE",
        "ITCHING",
        "JACKALS",
        "JESSICA",
        "JOINING",
        "JOURNAL",
        "JOURNEY",
        "JUNGLES",
        "JUSTICE",
        "JUTTING",
        "KEDRICK",
        "KEEPING",
        "KIDNAPS",
        "KILLING",
        "KINDRED",
        "KITCHEN",
        "KNIGHTS",
        "KNOWING",
        "LABELED",
        "LANDING",
        "LANTERN",
        "LARGEST",
        "LAUGHED",
        "LAUNDRY",
        "LAWLESS",
        "LEADERS",
        "LEADING",
        "LEARNED",
        "LEATHER",
        "LEAVING",
        "LECTURE",
        "LEGENDS",
        "LEGIONS",
        "LENDING",
        "LEPROSY",
        "LETTING",
        "LIBERAL",
        "LIBRARY",
        "LIGHTER",
        "LIMITED",
        "LOCALES",
        "LOCATED",
        "LOCKING",
        "LOOKING",
        "LOOTING",
        "LOWDOWN",
        "LOYALTY",
        "LURKING",
        "MACHETE",
        "MACHINE",
        "MALTASE",
        "MANAGED",
        "MANAGES",
        "MANHOOD",
        "MANKIND",
        "MASSIVE",
        "MASTERS",
        "MASTERY",
        "MATCHES",
        "MATTERS",
        "MAXIMUM",
        "MEANING",
        "MEETING",
        "MELISSA",
        "MELTING",
        "MEMBERS",
        "MENTION",
        "MESSAGE",
        "MESSIAH",
        "METHODS",
        "MICHAEL",
        "MILLING",
        "MINIGUN",
        "MINIMAL",
        "MIRRORS",
        "MISSING",
        "MISSION",
        "MIXTURE",
        "MOLOTOV",
        "MONITOR",
        "MONSTER",
        "MONTHLY",
        "MORNING",
        "MOTIVES",
        "MOUNTED",
        "MUTANTS",
        "MUTATED",
        "MYSTERY",
        "NATURAL",
        "NEGLECT",
        "NEITHER",
        "NERVOUS",
        "NOTABLE",
        "NOTHING",
        "NOTICED",
        "NOURISH",
        "NOWHERE",
        "NUCLEAR",
        "NULLMOD",
        "NUMBERS",
        "OBJECTS",
        "OBVIOUS",
        "OCTOBER",
        "OFFENSE",
        "OFFERED",
        "OFFICER",
        "OFFICES",
        "OFFLINE",
        "ONESELF",
        "OPENING",
        "OPTIONS",
        "ORBITAL",
        "ORDERED",
        "ORIGINS",
        "ORLEANS",
        "OUTCAST",
        "OUTCOME",
        "OUTLAWS",
        "OUTPOST",
        "OUTRAGE",
        "OUTSIDE",
        "OVERALL",
        "OVERLAP",
        "OVERRUN",
        "OVERSEE",
        "PACINKO",
        "PACKETS",
        "PACKING",
        "PARENTS",
        "PARTIES",
        "PASSING",
        "PASSION",
        "PATCHES",
        "PATTERN",
        "PENALTY",
        "PERFECT",
        "PERIODS",
        "PERSONA",
        "PHYSICS",
        "PICTURE",
        "PILLAGE",
        "PILLOWS",
        "PISTOLS",
        "PITIFUL",
        "PLAGUED",
        "PLANNED",
        "PLASTIC",
        "PLAYERS",
        "PLAYING",
        "PLEASED",
        "PLOTTED",
        "PLUMING",
        "POISONS",
        "POPULAR",
        "POURING",
        "POWERED",
        "PRAISED",
        "PRECISE",
        "PREPARE",
        "PRESENT",
        "PRESSED",
        "PRIESTS",
        "PRIMATE",
        "PRISONS",
        "PRIVATE",
        "PROBLEM",
        "PROCEED",
        "PROCESS",
        "PRODUCE",
        "PROJECT",
        "PROTECT",
        "PROVIDE",
        "PROWESS",
        "PSIONIC",
        "PSYCHIC",
        "PUPPETS",
        "PURPOSE",
        "PUTTING",
        "PUZZLES",
        "PYRAMID",
        "QUALIFY",
        "QUALITY",
        "QUICKLY",
        "RAIDERS",
        "RAIDING",
        "RAMPAGE",
        "RANGERS",
        "RANKING",
        "RANSACK",
        "RATIONS",
        "RAVAGES",
        "REACHED",
        "REACHES",
        "REACTOR",
        "READILY",
        "READING",
        "REALIZE",
        "REASONS",
        "REBUILD",
        "RECEIVE",
        "RECORDS",
        "RECOVER",
        "RECRUIT",
        "REDUCED",
        "REENTER",
        "REFUSES",
        "REGULAR",
        "RELATED",
        "RELEASE",
        "REMAINS",
        "REMORSE",
        "REMOVES",
        "REQUIRE",
        "RESIDES",
        "RESPECT",
        "RESTING",
        "RETIRED",
        "RETREAT",
        "RETURNS",
        "REVENGE",
        "REVERED",
        "RHOMBUS",
        "RITUALS",
        "ROAMING",
        "ROBBERS",
        "ROBERTS",
        "RODENTS",
        "ROUTING",
        "RUMBLES",
        "RUNDOWN",
        "RUNNING",
        "SALVAGE",
        "SANCTUM",
        "SAVIORS",
        "SCALPEL",
        "SCONCES",
        "SCRAPER",
        "SCREENS",
        "SCRIBES",
        "SEALANT",
        "SEALING",
        "SEASIDE",
        "SECRETS",
        "SECTION",
        "SEEKING",
        "SELLING",
        "SENDING",
        "SERIOUS",
        "SERMONS",
        "SERVANT",
        "SERVICE",
        "SERVING",
        "SESSION",
        "SETTING",
        "SETTLED",
        "SEVERAL",
        "SHADOWS",
        "SHARPER",
        "SHELTER",
        "SHELVES",
        "SHERIFF",
        "SHOTGUN",
        "SHOWING",
        "SHRINES",
        "SHUNNED",
        "SIGNALS",
        "SIMILAR",
        "SIPHONS",
        "SKEPTIC",
        "SKETCHY",
        "SKILLED",
        "SLAMMED",
        "SLAVERS",
        "SLAVERY",
        "SLIDING",
        "SLIPPED",
        "SLITHER",
        "SLUMBER",
        "SMALLER",
        "SMARTER",
        "SMASHED",
        "SMOKING",
        "SOCIETY",
        "SOLDIER",
        "SOMEHOW",
        "SOMEONE",
        "SOUNDED",
        "SPARING",
        "SPECIAL",
        "SPOTTED",
        "STACKED",
        "STAINED",
        "STAMINA",
        "STARTED",
        "STATING",
        "STATION",
        "STATUES",
        "STAYING",
        "STEALTH",
        "STERILE",
        "STOPPED",
        "STORAGE",
        "STORIES",
        "STORMED",
        "STRANGE",
        "STREAKS",
        "STREETS",
        "STRIPES",
        "STUDIES",
        "STUNNED",
        "SUBJECT",
        "SUCCEED",
        "SUCCESS",
        "SUGGEST",
        "SUPPORT",
        "SURFACE",
        "SURVIVE",
        "SYSTEMS",
        "TACTICS",
        "TAINTED",
        "TAKINGS",
        "TALENTS",
        "TALKING",
        "TARGETS",
        "TATTOOS",
        "TAUNTED",
        "TEACHER",
        "TEMPLES",
        "TENANTS",
        "TESTING",
        "THEATER",
        "THEATRE",
        "THICKER",
        "THIEVES",
        "THIRSTY",
        "THOUGHT",
        "THROUGH",
        "THROWER",
        "TONIGHT",
        "TOPPLED",
        "TORCHES",
        "TORTURE",
        "TOWARDS",
        "TRADERS",
        "TRADING",
        "TRAINED",
        "TRAITOR",
        "TREATED",
        "TRINITY",
        "TROUSER",
        "TRUSTED",
        "TUNNELS",
        "TURRETS",
        "TWINKIE",
        "TWISTED",
        "TYPICAL",
        "TYRANNY",
        "UNDERGO",
        "UNKNOWN",
        "UNLUCKY",
        "UNUSUAL",
        "URANIUM",
        "USELESS",
        "USUALLY",
        "UTENSIL",
        "VARIETY",
        "VARIOUS",
        "VARYING",
        "VASSALS",
        "VEGGIES",
        "VENTURE",
        "VERSION",
        "VICTIMS",
        "VICTORY",
        "VILLAGE",
        "VILLAIN",
        "VIOLATE",
        "VIOLENT",
        "VIRTUAL",
        "VISIBLE",
        "VISITED",
        "VOLUMES",
        "WAITING",
        "WALKING",
        "WALKWAY",
        "WANTING",
        "WARFARE",
        "WARLIKE",
        "WARNING",
        "WARPATH",
        "WARRING",
        "WARRIOR",
        "WASTING",
        "WATCHED",
        "WEALTHY",
        "WEAPONS",
        "WEARING",
        "WELCOME",
        "WELFARE",
        "WESTERN",
        "WHETHER",
        "WHISPER",
        "WHOEVER",
        "WILLING",
        "WINDING",
        "WINDOWS",
        "WINNING",
        "WISHING",
        "WITHOUT",
        "WONDERS",
        "WORKING",
        "WORRIED",
        "WORSHIP",
        "WOUNDED",
        "WRITING",
        "WRITTEN",
        "YOUNGER",
        "ZEALOTS",
        "ZEALOUS"
    ],
    "8": [
        "ABNORMAL",
        "ABSOLUTE",
        "ACCIDENT",
        "ACCURACY",
        "ACQUIRED",
        "ACROBATS",
        "ACTIVATE",
        "ACTIVELY",
        "ACTUALLY",
        "ADEQUATE",
        "ADJUSTED",
        "ADVANCED",
        "AGREEING",
        "ALLIANCE",
        "ALTERING",
        "ALTHOUGH",
        "AMBITION",
        "AMBUSHED",
        "ANDROIDS",
        "ANIMATED",
        "ANNOUNCE",
        "ANYTHING",
        "ANYWHERE",
        "APPARENT",
        "APPEARED",
        "APPROACH",
        "AQUARIUS",
        "ARCHIVES",
        "ARRESTED",
        "ARSENALS",
        "ARTIFACT",
        "ASCENDED",
        "ASSASSIN",
        "ASSEMBLE",
        "ASSEMBLY",
        "ASSIGNED",
        "ATTACHED",
        "ATTACKED",
        "ATTEMPTS",
        "AUDIENCE",
        "AWAKENED",
        "BACKPACK",
        "BANDITOS",
        "BARBARIC",
        "BARGHEST",
        "BARRACKS",
        "BASEMENT",
        "BATHROOM",
        "BECOMING",
        "BEDROOMS",
        "BEHAVIOR",
        "BELIEVED",
        "BELIEVES",
        "BENEFITS",
        "BETRAYAL",
        "BLINKING",
        "BLUDGEON",
        "BONEYARD",
        "BORROWED",
        "BOTHERED",
        "BRANCHES",
        "BRAWLING",
        "BREAKERS",
        "BREAKING",
        "BREATHED",
        "BRIGANDS",
        "BROTHERS",
        "BRUTALLY",
        "BUILDING",
        "BUSINESS",
        "CACKLING",
        "CALIGULA",
        "CANISTER",
        "CAPACITY",
        "CAPTURED",
        "CARAVANS",
        "CARPETED",
        "CARRYING",
        "CATWALKS",
        "CELLULAR",
        "CEMETERY",
        "CEREMONY",
        "CHAINSAW",
        "CHAMBERS",
        "CHAMPION",
        "CHARISMA",
        "CHASTISE",
        "CHEMICAL",
        "CHILDREN",
        "CITADELS",
        "CITIZENS",
        "CLAIMING",
        "CLEANING",
        "CLIMBING",
        "CLOTHING",
        "COCKTAIL",
        "COLISEUM",
        "COLLAPSE",
        "COMMANDS",
        "COMPARED",
        "COMPLETE",
        "COMPOUND",
        "COMPUTER",
        "CONCERNS",
        "CONCRETE",
        "CONDUCTS",
        "CONFINED",
        "CONFLICT",
        "CONFRONT",
        "CONNECTS",
        "CONQUERS",
        "CONQUEST",
        "CONSISTS",
        "CONSTANT",
        "CONTAINS",
        "CONTENTS",
        "CONTESTS",
        "CONTINUE",
        "CONTRAST",
        "CONTROLS",
        "CONVERSE",
        "CONVINCE",
        "CORRIDOR",
        "CORRODED",
        "COURTESY",
        "COWARDLY",
        "CRACKING",
        "CRAWLING",
        "CREATING",
        "CREMATED",
        "CRIMINAL",
        "CROSSBOW",
        "CRUCIBLE",
        "CURTAINS",
        "CUSTOMER",
        "DARKNESS",
        "DAUGHTER",
        "DEBATING",
        "DECLARED",
        "DECORATE",
        "DECREPIT",
        "DEFEATED",
        "DEFENDED",
        "DEFENSES",
        "DEFINITE",
        "DELIRIUM",
        "DELIVERS",
        "DEMANDED",
        "DESCRIBE",
        "DESIGNED",
        "DESOLATE",
        "DESPISES",
        "DESTROYS",
        "DESTRUCT",
        "DETAILED",
        "DETECTOR",
        "DEVOURED",
        "DIAGRAMS",
        "DICTATES",
        "DIRECTLY",
        "DISBANDS",
        "DISCOVER",
        "DISGUISE",
        "DISLIKES",
        "DISPOSAL",
        "DISPROVE",
        "DISPUTES",
        "DISTANCE",
        "DISTRUST",
        "DIVINITY",
        "DIVISION",
        "DOCUMENT",
        "DOWNHILL",
        "DRAMATIC",
        "DRENCHED",
        "DUNGEONS",
        "DWELLERS",
        "DYNAMITE",
        "EGYPTIAN",
        "ELECTRIC",
        "ELEMENTS",
        "ELEVATOR",
        "ELIGIBLE",
        "ENABLING",
        "ENDEAVOR",
        "ENFORCED",
        "ENGAGING",
        "ENGINEER",
        "ENGULFED",
        "ENHANCED",
        "ENORMOUS",
        "ENSLAVED",
        "ENTIRELY",
        "ENTRANCE",
        "EQUIPPED",
        "ESCAPING",
        "ESCORTED",
        "EVERYDAY",
        "EVERYONE",
        "EVIDENCE",
        "EXCITING",
        "EXERCISE",
        "EXPANDED",
        "EXPLAINS",
        "EXPLORED",
        "EXPLORES",
        "EXPOSURE",
        "EXTENDED",
        "EXTERIOR",
        "FACILITY",
        "FACTIONS",
        "FAITHFUL",
        "FAMILIAR",
        "FAMILIES",
        "FANATICS",
        "FAREWELL",
        "FARTHEST",
        "FATALITY",
        "FAVORITE",
        "FEATHERS",
        "FEBRUARY",
        "FEROCITY",
        "FEVERISH",
        "FIERCELY",
        "FIGHTERS",
        "FIGHTING",
        "FINISHED",
        "FIREARMS",
        "FLOURISH",
        "FOLLOWED",
        "FOLLOWER",
        "FORAGING",
        "FORTRESS",
        "FREQUENT",
        "FRIENDLY",
        "GANGSTER",
        "GARRISON",
        "GATHERED",
        "GENERATE",
        "GENETICS",
        "GIGANTIC",
        "GOVERNOR",
        "GRASPING",
        "GRATEFUL",
        "GREENERY",
        "GRIEVOUS",
        "GRUELING",
        "GUARDIAN",
        "GUARDING",
        "HALLWAYS",
        "HAPPENED",
        "HARDWARE",
        "HEGEMONY",
        "HERETICS",
        "HIDEAWAY",
        "HIDEOUTS",
        "HOLDINGS",
        "HOLLOWED",
        "HOLOGRAM",
        "HORRIBLE",
        "HORRIBLY",
        "HOSPITAL",
        "HUMANITY",
        "HUMOROUS",
        "HUNDREDS",
        "IMAGINED",
        "IMMERSED",
        "IMPLANTS",
        "IMPOSING",
        "IMPROVED",
        "IMPURITY",
        "INFAMOUS",
        "INFECTED",
        "INFERIOR",
        "INFESTED",
        "INFORMED",
        "INFRARED",
        "INITIATE",
        "INJECTED",
        "INJURIES",
        "INSANITY",
        "INSERTED",
        "INSPIRED",
        "INSULOMA",
        "INTENDED",
        "INTERCOM",
        "INTERIOR",
        "INTERNAL",
        "INTRUDER",
        "INVADERS",
        "INVENTED",
        "INVENTOR",
        "INVOLVED",
        "JONATHAN",
        "JOURNALS",
        "JUMPSUIT",
        "JUNCTION",
        "JUNKTOWN",
        "KNEECAPS",
        "KNIGHTLY",
        "KNUCKLES",
        "LANGUAGE",
        "LAUNCHED",
        "LAUNCHER",
        "LEARNING",
        "LEATHERS",
        "LEVERAGE",
        "LIBERALS",
        "LIGHTING",
        "LIKEWISE",
        "LISTENED",
        "LOCATION",
        "LOCKPICK",
        "LOYALIST",
        "LUNATICS",
        "MACHINES",
        "MAINLAND",
        "MAINTAIN",
        "MAJORITY",
        "MANSIONS",
        "MARKINGS",
        "MASSACRE",
        "MATERIAL",
        "MAYVILLE",
        "MEASURES",
        "MECHANIC",
        "MEETINGS",
        "MERCHANT",
        "MESSAGES",
        "METALLIC",
        "MIDNIGHT",
        "MILITARY",
        "MILLIONS",
        "MINISHEN",
        "MISSILES",
        "MISSIONS",
        "MISTAKES",
        "MODIFIED",
        "MOLECULE",
        "MONETARY",
        "MONITORS",
        "MONSTERS",
        "MORPHEUS",
        "MOUNTAIN",
        "MOURNING",
        "MOVEMENT",
        "MULBERRY",
        "MULTIPLE",
        "MURDERER",
        "MUSCLING",
        "MUSCULAR",
        "MUSHROOM",
        "MUTATION",
        "MUTATNTS",
        "MYSTICAL",
        "NIGHTKIN",
        "NORMALLY",
        "NORTHERN",
        "NOWADAYS",
        "NUMBERED",
        "OBLIVION",
        "OBSESSED",
        "OCCUPIED",
        "OFFICERS",
        "OPENINGS",
        "OPPONENT",
        "OPPOSITE",
        "ORDERING",
        "ORDINARY",
        "ORIGINAL",
        "OUTCASTS",
        "OUTRAGED",
        "OUTRIGHT",
        "OUTSIDER",
        "OVERCOME",
        "OVERSEER",
        "OVERSEES",
        "PAINTING",
        "PANICKED",
        "PARANOIA",
        "PARANOID",
        "PASSKEYS",
        "PATIENTS",
        "PEACEFUL",
        "PERILOUS",
        "PERSISTS",
        "PERSONAL",
        "PHYSICAL",
        "PICTURES",
        "PILGRIMS",
        "PLANNING",
        "PLATFORM",
        "PLEASANT",
        "PLEASURE",
        "PLOTTING",
        "PONYTAIL",
        "PORTABLE",
        "PORTIONS",
        "POSITION",
        "POSSIBLE",
        "POSSIBLY",
        "POUNDING",
        "POWERFUL",
        "PRECIOUS",
        "PREDATOR",
        "PREPARED",
        "PRESENCE",
        "PRESERVE",
        "PRESSURE",
        "PRESUMED",
        "PREVIOUS",
        "PRIORITY",
        "PRISONER",
        "PRISTINE",
        "PROBABLY",
        "PROBLEMS",
        "PRODUCED",
        "PROGRESS",
        "PROJECTS",
        "PROMISES",
        "PROPERLY",
        "PROPERTY",
        "PROTECTS",
        "PROVIDED",
        "PROVIDES",
        "PURCHASE",
        "PURIFIER",
        "PURPOSES",
        "QUARTERS",
        "QUESTING",
        "RACCOONS",
        "RADSUITS",
        "RANDOMLY",
        "RANSACKS",
        "REACTION",
        "REALIZED",
        "RECEIVES",
        "RECORDED",
        "REFUGEES",
        "REFUNDED",
        "RELATIVE",
        "RELEASED",
        "RELIEVED",
        "RELIGION",
        "REMAINED",
        "REMEMBER",
        "REMOVING",
        "REMUSTER",
        "REPAIRED",
        "REPELLED",
        "REPLACED",
        "REPORTED",
        "REPTILES",
        "REQUIRES",
        "RESEARCH",
        "RESEMBLE",
        "RESERVES",
        "RESIDENT",
        "RESIDING",
        "RESULTED",
        "RETRIEVE",
        "RETURNED",
        "REVEALED",
        "REVERTED",
        "REVOLTED",
        "REWARDED",
        "RHYTHMIC",
        "RIGHTFUL",
        "RUSTLING",
        "RUTHLESS",
        "SABOTAGE",
        "SADISTIC",
        "SALVAGED",
        "SAMANTHA",
        "SAMANTHA",
        "SATURDAY",
        "SAVAGERY",
        "SCHEDULE",
        "SCHEMING",
        "SCORPION",
        "SCOUTING",
        "SCRUBBED",
        "SEARCHED",
        "SECRETLY",
        "SECTIONS",
        "SECURITY",
        "SENTENCE",
        "SERVANTS",
        "SERVITOR",
        "SETTLING",
        "SHACKLES",
        "SHIELDED",
        "SHIPMENT",
        "SHOOTING",
        "SHOTGUNS",
        "SHOULDER",
        "SHROUDED",
        "SHRUGGED",
        "SHUTTING",
        "SIGNALER",
        "SILENCER",
        "SINISTER",
        "SKELETAL",
        "SLEEPING",
        "SLIGHTLY",
        "SMOOTHLY",
        "SNEAKING",
        "SOFTWARE",
        "SOLDIERS",
        "SOLITARY",
        "SOLUTION",
        "SOMEWHAT",
        "SOUTHERN",
        "SPEAKERS",
        "SPECTRAL",
        "SPEECHES",
        "SPEEDING",
        "SPENDING",
        "SPLINTER",
        "SPYGLASS",
        "STAIRWAY",
        "STANDARD",
        "STANDING",
        "STARSHIP",
        "STARTING",
        "STATUARY",
        "STEALING",
        "STEALTHY",
        "STEMMING",
        "STILETTO",
        "STRAIGHT",
        "STRENGTH",
        "STRIKING",
        "STRONGER",
        "STRUGGLE",
        "STUDENTS",
        "STUDYING",
        "STUMBLED",
        "STUMBLES",
        "STUNNERS",
        "SUBJECTS",
        "SUBURBAN",
        "SUCCEEDS",
        "SUDDENLY",
        "SUFFERED",
        "SUNDRIES",
        "SUPERIOR",
        "SUPPLIED",
        "SUPPLIES",
        "SUPPOSED",
        "SURPRISE",
        "SURROUND",
        "SURVIVAL",
        "SURVIVED",
        "SURVIVOR",
        "SYNOPSES",
        "SYNOPSIS",
        "TACTICAL",
        "TEMPORAL",
        "TENSIONS",
        "THANKFUL",
        "THINKING",
        "THOUGHTS",
        "THOUSAND",
        "THRIVING",
        "THROWERS",
        "THROWING",
        "TIAJUANA",
        "TIMELINE",
        "TOGETHER",
        "TOMATOES",
        "TORTURED",
        "TRACKING",
        "TRAINING",
        "TRAITORS",
        "TRANSFER",
        "TRANSMIT",
        "TRAPDOOR",
        "TRAVELED",
        "TREASURE",
        "TRINKETS",
        "TRIPPING",
        "TROUBLES",
        "TRUSTING",
        "TWILIGHT",
        "TWITCHES",
        "ULTIMATE",
        "UNCOMMON",
        "UNCOVERS",
        "UNDERWAY",
        "UNLIKELY",
        "UNLOCKED",
        "UNMANNED",
        "UNRAVELS",
        "UNVEILED",
        "UNWANTED",
        "VILLAGER",
        "VILLAGES",
        "VIOLENCE",
        "VIRTURAL",
        "VISITORS",
        "VOLATILE",
        "WANDERED",
        "WARRIORS",
        "WATCHFUL",
        "WEAPONRY",
        "WELCOMED",
        "WHATEVER",
        "WHENEVER",
        "WHISTLED",
        "WILDFIRE",
        "WITHDRAW",
        "WRECKAGE"
    ],
    "9": [
        "ABANDONED",
        "ABSORBING",
        "ACCEPTING",
        "ACCOMPANY",
        "ACCORDING",
        "ADDRESSED",
        "ADVENTURE",
        "ADVERTISE",
        "AFTERNOON",
        "AGREEMENT",
        "AMBITIONS",
        "AMERICANS",
        "AMPLIFIES",
        "ANNOUNCES",
        "APARTMENT",
        "APOLOGIZE",
        "ARMADILLO",
        "ARRANGING",
        "ARTIFACTS",
        "ASCENSION",
        "ASSAULTED",
        "ASSISTANT",
        "ATTACKERS",
        "ATTEMPTED",
        "ATTENTION",
        "AUTHORITY",
        "AUTOMATED",
        "AVAILABLE",
        "AWAKENING",
        "BACKSTABS",
        "BANDOLEER",
        "BARNSTORM",
        "BARTERING",
        "BASICALLY",
        "BATHROOMS",
        "BATTERIES",
        "BEAUTIFUL",
        "BEGINNING",
        "BELIEVING",
        "BELONGING",
        "BLACKJACK",
        "BLASPHEMY",
        "BLOODLUST",
        "BLOODSHED",
        "BODYGUARD",
        "BREAKFAST",
        "BRIEFCASE",
        "BROADCAST",
        "BRUTALITY",
        "BUILDINGS",
        "CANISTERS",
        "CAPTURING",
        "CARDINALS",
        "CARPETING",
        "CARRIAGES",
        "CATACOMBS",
        "CATHEDRAL",
        "CERTAINLY",
        "CHALLENGE",
        "CHARACTER",
        "CHEMICALS",
        "CHEMISTRY",
        "CHILDLIKE",
        "CHITINOUS",
        "CIRCUITRY",
        "CLOCKWORK",
        "CLUSTERED",
        "CLUTTERED",
        "COCKROACH",
        "COLLECTED",
        "COLLECTOR",
        "COMMANDED",
        "COMMITTEE",
        "COMMUNITY",
        "COMPANIES",
        "COMPLETES",
        "COMPUTERS",
        "CONCEALED",
        "CONCERNED",
        "CONDUCTED",
        "CONFUSING",
        "CONQUORER",
        "CONSIDERS",
        "CONSISTED",
        "CONSTRUCT",
        "CONTAINED",
        "CONTINUED",
        "CONVERTED",
        "CONVICTED",
        "CONVINCED",
        "CONVINCES",
        "CORPORATE",
        "CORRECTLY",
        "COUNTDOWN",
        "COUNTRIES",
        "COURTYARD",
        "CREATURES",
        "CRIMINALS",
        "CRUMBLING",
        "CURIOSITY",
        "CURRENTLY",
        "CUSTOMERS",
        "DANGEROUS",
        "DEATHCLAW",
        "DECISIONS",
        "DEDICATED",
        "DEFEATING",
        "DEFENDERS",
        "DEFENSIVE",
        "DEFORMITY",
        "DELIMITER",
        "DELIVERED",
        "DEPENDING",
        "DESERTERS",
        "DESPERATE",
        "DESTROYED",
        "DETERMINE",
        "DETERRENT",
        "DETHRONED",
        "DEVELOPED",
        "DIFFERENT",
        "DIFFICULT",
        "DIRECTION",
        "DISAGREES",
        "DISAPPEAR",
        "DISCOVERY",
        "DISGRACED",
        "DISGUISES",
        "DISGUSTED",
        "DISPARATE",
        "DISPLAYED",
        "DISTANCES",
        "DIVISIONS",
        "DOCTRINES",
        "DOCUMENTS",
        "DOWNRIGHT",
        "DURASTEEL",
        "DWINDLING",
        "ELABORATE",
        "ELIMINATE",
        "ELSEWHERE",
        "EMERGENCY",
        "ENCOUNTER",
        "ENDORPHIN",
        "ENGINEERS",
        "ENTRANCES",
        "EQUIPMENT",
        "ESTABLISH",
        "EVERYTIME",
        "EXCHANGED",
        "EXECUTION",
        "EXPENSIVE",
        "EXPLORING",
        "EXPLOSIVE",
        "EXPRESSES",
        "EXTENSIVE",
        "EXTREMELY",
        "FANATICAL",
        "FAVORABLE",
        "FEARFULLY",
        "FEROCIOUS",
        "FINALIZES",
        "FIREFIGHT",
        "FLATTENED",
        "FLAVORING",
        "FOLLOWERS",
        "FOLLOWING",
        "FOOTSTEPS",
        "FORBIDDEN",
        "FORGOTTEN",
        "FORTIFIED",
        "FORTITUDE",
        "FURNITURE",
        "GATHERING",
        "GENERALLY",
        "GENERATED",
        "GENERATOR",
        "GENTLEMAN",
        "GLADIATOR",
        "GODFATHER",
        "GOSSIPING",
        "GRADUALLY",
        "GRAPPLING",
        "GRATITUDE",
        "GROTESQUE",
        "GUARDIANS",
        "GYMNASIUM",
        "HANDCUFFS",
        "HAPPENING",
        "HAPPINESS",
        "HARNESSED",
        "HEALTHIER",
        "HIGHTOWER",
        "HISTORIES",
        "HOLOCAUST",
        "HONORABLE",
        "HUMANKIND",
        "HURRIEDLY",
        "IDENTICAL",
        "IGNORANCE",
        "ILLNESSES",
        "IMMEDIATE",
        "IMPLANTED",
        "IMPORTANT",
        "IMPROVING",
        "INCESSANT",
        "INCLUDING",
        "INCREASED",
        "INDICATED",
        "INFLUENCE",
        "INITIALLY",
        "INITIATES",
        "INNERMOST",
        "INNOCENTS",
        "INSISTENT",
        "INSTANTLY",
        "INSTINCTS",
        "INTELLECT",
        "INTERCEPT",
        "INTERFACE",
        "INTERIORS",
        "INTRICATE",
        "INTRUSION",
        "INVENTORS",
        "INVOLVING",
        "ISOLATION",
        "JAILBREAK",
        "KIDNAPPED",
        "KNOWLEDGE",
        "LEGENDARY",
        "LEUTENANT",
        "LISTENING",
        "LITERALLY",
        "LOCATIONS",
        "LOCKPICKS",
        "MACHINERY",
        "MAGICIANS",
        "MAGNITUDE",
        "MARAUDERS",
        "MARAUDING",
        "MARVELOUS",
        "MECHANISM",
        "MENTIONED",
        "MERCHANTS",
        "MERCILESS",
        "MESSIANIC",
        "MICROCHIP",
        "MICROWAVE",
        "MONASTERY",
        "MONITORED",
        "MONOBLADE",
        "MONOCOLOR",
        "MONSTROUS",
        "MOONSHINE",
        "MOUNTAINS",
        "MURDEROUS",
        "MUTATIONS",
        "NECESSARY",
        "NEGOTIATE",
        "NIGHTCLUB",
        "NORTHWEST",
        "NOTORIOUS",
        "OBJECTIVE",
        "OBTAINING",
        "OBVIOUSLY",
        "OCCASIONS",
        "OCCUPANTS",
        "OCCUPYING",
        "OFFICIALS",
        "OFFSPRING",
        "OPERATING",
        "OPERATION",
        "OTHERWISE",
        "OUTSIDERS",
        "OUTSKIRTS",
        "OVERMATCH",
        "OVERPRICE",
        "OVERTHROW",
        "PACIFISTS",
        "PAINTINGS",
        "PANTHEIST",
        "PARALYSES",
        "PARTICLES",
        "PATIENTLY",
        "PATROLLED",
        "PENDELTON",
        "PERFORMED",
        "PERIMETER",
        "PERSONNEL",
        "PIERCINGS",
        "PLUNDERED",
        "POISONING",
        "POISONOUS",
        "PONDEROUS",
        "POSITIONS",
        "POSSESSES",
        "PRAGMATIC",
        "PRECISION",
        "PREDATORS",
        "PREPARING",
        "PRESENTED",
        "PRIMARILY",
        "PRIMITIVE",
        "PRISONERS",
        "PROCESSOR",
        "PROJECTOR",
        "PROLONGED",
        "PROTECTED",
        "PURIFYING",
        "QUALITIES",
        "QUESTIONS",
        "RADIATION",
        "RAMBLINGS",
        "RANSACKED",
        "REALISTIC",
        "REARGUARD",
        "RECEIVING",
        "RECEPTORS",
        "RECOGNIZE",
        "RECOVERED",
        "RECYCLING",
        "REFERENCE",
        "REFRESHED",
        "REGARDING",
        "REGULATES",
        "RELEASERS",
        "RELEASING",
        "RELIGIONS",
        "RELIGIOUS",
        "REMAINING",
        "REMINDING",
        "RENOVATED",
        "REPAIRING",
        "REPELLENT",
        "REPLICATE",
        "REPRESSED",
        "REPRIMAND",
        "REQUIRING",
        "RESEMBLES",
        "RESERVOIR",
        "RESIDENCE",
        "RESIDENTS",
        "RESILIENT",
        "RESOURCES",
        "RESPECTED",
        "RETREATED",
        "RETURNING",
        "REVERENCE",
        "SACRIFICE",
        "SALVATION",
        "SANCTUARY",
        "SCATTERED",
        "SCIENTIST",
        "SCORPIONS",
        "SCRIPTING",
        "SCRUBBERS",
        "SEARCHING",
        "SECLUSION",
        "SECRETIVE",
        "SELECTING",
        "SEPARATED",
        "SEPTEMBER",
        "SERIOUSLY",
        "SERVITORS",
        "SHAMBLING",
        "SITUATION",
        "SKEPTICAL",
        "SLITHERED",
        "SNAKELIKE",
        "SOMETHING",
        "SOMETIMES",
        "SOMEWHERE",
        "SOUTHEAST",
        "SOUTHWARD",
        "SPIRITUAL",
        "SPONSORED",
        "SPREADING",
        "STARTLING",
        "STATIONED",
        "STERILIZE",
        "STOREROOM",
        "STRANGERS",
        "STRANGEST",
        "STRETCHES",
        "STRONGEST",
        "STRUCTURE",
        "STRUGGLES",
        "STUMBLING",
        "SUBJECTED",
        "SUICIDALY",
        "SUMMONING",
        "SURRENDER",
        "SURROUNDS",
        "SURVIVING",
        "SURVIVORS",
        "SUSPECTED",
        "SWIVELING",
        "TECHNICAL",
        "TELEPHONE",
        "TEMPORARY",
        "TERRIFIED",
        "TERRITORY",
        "THEREFORE",
        "THREATENS",
        "TOLERANCE",
        "TORTURING",
        "TRAVELERS",
        "TRAVELING",
        "TREATMENT",
        "TRIUMPHED",
        "TROUBLING",
        "TWOBYFOUR",
        "UNCOVERED",
        "UNDERGONE",
        "UNDERWENT",
        "UNIVERSAL",
        "UNLOCKING",
        "UNTOUCHED",
        "VAPORIZES",
        "VENGEANCE",
        "VIGILANCE",
        "VILLAGERS",
        "VIOLENTLY",
        "VIRTUALLY",
        "VOLUNTEER",
        "WANDERING",
        "WAREHOUSE",
        "WASTELAND",
        "WASTELORD",
        "WHISPERED",
        "WILLINGLY",
        "WONDERFUL",
        "WORSHIPER",
        "WRENCHING"
    ],
    "10": [
        "ABSOLUTELY",
        "ACCEPTANCE",
        "ACCURATELY",
        "ACCUSATION",
        "ACTIVATING",
        "ACTIVITIES",
        "ADDITIONAL",
        "ADMIRATION",
        "ADRENALINE",
        "ADVANTAGES",
        "ADVENTURES",
        "ADVERTISES",
        "AGGRESSIVE",
        "ALLEGIANCE",
        "APARTMENTS",
        "APOCALYPSE",
        "APPEARANCE",
        "APPROACHED",
        "APPROACHES",
        "ARBITRATOR",
        "ARCHITECTS",
        "ARCHPRIEST",
        "ARMAGEDDON",
        "ARMORPLAST",
        "ARTIFICIAL",
        "ASSISTANCE",
        "ASSOCIATES",
        "ATMOSPHERE",
        "ATTRACTING",
        "AUTOMOBILE",
        "BACKGROUND",
        "BEDCHAMBER",
        "BEDRAGGLED",
        "BEFRIENDED",
        "BELONGINGS",
        "BENEVOLENT",
        "BINOCULARS",
        "BLACKSTAFF",
        "BOBBLEHEAD",
        "BODYGUARDS",
        "BRAINWAVES",
        "BROADCASTS",
        "BUSINESSES",
        "CALCULATED",
        "CALIFORNIA",
        "CAMOUFLAGE",
        "CASUALTIES",
        "CEREMONIAL",
        "CEREMONIES",
        "CHALLENGED",
        "CHALLENGER",
        "CHARACTERS",
        "CHOKEPOINT",
        "COMMISSION",
        "COMPANIONS",
        "COMPLETELY",
        "COMPLETION",
        "COMPLEXION",
        "CONCERNING",
        "CONCLUSION",
        "CONCOCTION",
        "CONDUCTING",
        "CONFRONTED",
        "CONSIDERED",
        "CONSISTING",
        "CONSPIRACY",
        "CONSPIRING",
        "CONSTANTLY",
        "CONTAINERS",
        "CONTAINING",
        "CONTENDING",
        "CONTENTING",
        "CONTINGENT",
        "CONVERSION",
        "CROSSROADS",
        "CUCARACHAS",
        "CURRICULUM",
        "CUTTHROATS",
        "DEATHCLAWS",
        "DECIMATING",
        "DEFINITELY",
        "DEFINITION",
        "DELIGHTING",
        "DELIVERING",
        "DEPARTMENT",
        "DESCENDENT",
        "DESCENDING",
        "DESTROYING",
        "DETERMINED",
        "DIFFERENCE",
        "DIRECTIONS",
        "DISCIPLINE",
        "DISCOURAGE",
        "DISCOVERED",
        "DISHEVELED",
        "DISPLEASED",
        "DISPOSABLE",
        "DISSIPATES",
        "DISTURBING",
        "DOMINATION",
        "DOWNSTAIRS",
        "ELECTRICAL",
        "ELECTRONIC",
        "EMBODIMENT",
        "EMPLOYMENT",
        "ENCAMPMENT",
        "ENCOUNTERS",
        "ENCOURAGED",
        "ENDORPHINS",
        "ENVISIONED",
        "EQUIVALENT",
        "ERADICATES",
        "ESPECIALLY",
        "EVENTUALLY",
        "EVERYTHING",
        "EVERYWHERE",
        "EXCHANGERS",
        "EXCHANGING",
        "EXECUTIONS",
        "EXPEDITION",
        "EXPLOSIONS",
        "EXPLOSIVES",
        "FACILITIES",
        "FLASHLIGHT",
        "FLOURISHED",
        "FLOURISHES",
        "FOOTPRINTS",
        "FORCEFIELD",
        "FREQUENTLY",
        "FUNCTIONAL",
        "FUTURISTIC",
        "GENERATION",
        "GEOTHERMAL",
        "GLADIATORS",
        "GOVERNMENT",
        "HEIGHTENED",
        "HESITATION",
        "HISTORICAL",
        "HYPERLIGHT",
        "IMPOSSIBLE",
        "INCENDIARY",
        "INCREASING",
        "INCREDIBLE",
        "INCREDIBLY",
        "INDIVIDUAL",
        "INEVITABLE",
        "INEVITABLY",
        "INFILTRATE",
        "INHIBITORS",
        "INITIATION",
        "INSPECTION",
        "INSTRUCTOR",
        "INTENTIONS",
        "INTERFERES",
        "INTRODUCED",
        "INTRODUCES",
        "IRRADIATED",
        "KNIGHTHOOD",
        "LABORATORY",
        "LEADERLESS",
        "LEADERSHIP",
        "LIEUTENANT",
        "LIFESTYLES",
        "MAINSTREAM",
        "MAINTAINED",
        "MALEVOLENT",
        "MECHANICAL",
        "MEDITATION",
        "MONITORING",
        "MONOLITHIC",
        "MUTATIONAL",
        "MYSTERIOUS",
        "NECROPOLIS",
        "OBLITERATE",
        "OCCUPATION",
        "OFFICIALLY",
        "OPERATIONS",
        "OSTRACIZED",
        "OUTNUMBERS",
        "OUTRAGEOUS",
        "OVERLOOKED",
        "PEACEFULLY",
        "PERCENTAGE",
        "PERFORMERS",
        "PERSEVERED",
        "PHILOSOPHY",
        "PINPOINTED",
        "POPULATION",
        "POSSESSION",
        "PROCESSING",
        "PROCESSION",
        "PROCLAIMED",
        "PRODUCTION",
        "PRODUCTIVE",
        "PROGRESSES",
        "PROJECTION",
        "PROSPERITY",
        "PROTECTING",
        "PROTECTION",
        "PROTECTIVE",
        "PROVISIONS",
        "QUANTITIES",
        "RAMSHACKLE",
        "RANSACKING",
        "REASONABLY",
        "REBELLIOUS",
        "RECOGNIZES",
        "RECREATING",
        "RECRUITING",
        "RECUPERATE",
        "REFERENCES",
        "REINFORCED",
        "RELATIVELY",
        "REMAINDERS",
        "REMEMBERED",
        "REPUTATION",
        "RESEMBLING",
        "RESISTANCE",
        "RESOUNDING",
        "RESTAURANT",
        "RESTRICTED",
        "RETALIATED",
        "REVOLUTION",
        "SACRIFICED",
        "SACRIFICES",
        "SCANDALOUS",
        "SCAVENGERS",
        "SCAVENGING",
        "SCHEMATICS",
        "SCIENTIFIC",
        "SCIENTISTS",
        "SCULPTURES",
        "SEPARATING",
        "SEPARATION",
        "SETTLEMENT",
        "SITUATIONS",
        "SKYSCRAPER",
        "SOUTHWARDS",
        "SPECIALIZE",
        "STARVATION",
        "STIMULATED",
        "STOREROOMS",
        "STRANGLERS",
        "STRATEGIES",
        "STRATEGIST",
        "STRETCHING",
        "STRONGHOLD",
        "STRUCTURES",
        "SUCCESSFUL",
        "SUGGESTION",
        "SUNGLASSES",
        "SUPPRESSER",
        "SURRENDERS",
        "SURROUNDED",
        "SUSPICIOUS",
        "TAPESTRIES",
        "TECHNICIAN",
        "TECHNOLOGY",
        "TELEVISION",
        "THEMSELVES",
        "THEREAFTER",
        "THROUGHOUT",
        "TOOTHBRUSH",
        "TREATMENTS",
        "UNDERGOING",
        "UNDERNEATH",
        "UNDERSTAND",
        "UNDERWATER",
        "UNDETECTED",
        "UNEXPECTED",
        "UNFAITHFUL",
        "UNPREPARED",
        "VANQUISHED",
        "VEGETABLES",
        "VEHEMENTLY",
        "VOLUNTEERS",
        "VULNERABLE",
        "WASTELORDS",
        "WIDESPREAD",
        "WORSHIPERS",
        "WORTHINESS",
        "XENOPHOBES"
    ],
    "11": [
        "ACCELERATES",
        "ACCESSORIES",
        "ACCOMPANIED",
        "ACKNOWLEDGE",
        "AGRICULTURE",
        "ANIMALISTIC",
        "ANNIHILATED",
        "APPROPRIATE",
        "APPROXIMATE",
        "ASSASSINATE",
        "ATMOSPHERIC",
        "BACKGROUNDS",
        "BARTERTOWNE",
        "BECKONINGLY",
        "BLASPHEMERS",
        "BOMBARDMENT",
        "BRAINWASHED",
        "BROTHERHOOD",
        "CAMOUFLAGED",
        "CANDELABRAS",
        "CATEGORIZED",
        "CHALLENGING",
        "CHARISMATIC",
        "CHIMPANZEES",
        "COMBINATION",
        "COMFORTABLE",
        "CONNECTIONS",
        "CONSTRUCTED",
        "CONTAINMENT",
        "CONTROLLING",
        "COUNTERMAND",
        "DECEPTIVELY",
        "DESCENDANTS",
        "DESCRIPTION",
        "DESPERATELY",
        "DESTRUCTION",
        "DESTRUCTIVE",
        "DEVASTATION",
        "DISAPPEARED",
        "DISCIPLINES",
        "DISCOVERIES",
        "DISCOVERING",
        "DISMEMBERED",
        "DISTINGUISH",
        "DISTRUSTFUL",
        "EFFECTIVELY",
        "ELECTRICITY",
        "ELECTRIFIED",
        "ELECTRONICS",
        "ELIMINATING",
        "EMANCIPATED",
        "EMBROIDERED",
        "ENGINEERING",
        "ERRATICALLY",
        "ESSENTIALLY",
        "EXAMINATION",
        "EXCLUSIVELY",
        "EXPERIENCED",
        "EXPERIENCES",
        "EXPERIMENTS",
        "EXPLANATION",
        "FREELOADERS",
        "FURNISHINGS",
        "GENERATIONS",
        "GRANDFATHER",
        "HOLOGRAPHIC",
        "HOSPITALITY",
        "IMMEDIATELY",
        "IMPORTANTLY",
        "INDEPENDENT",
        "INDIVIDUALS",
        "INFATICALLY",
        "INFESTATION",
        "INFLUENTIAL",
        "INFORMATION",
        "INGREDIENTS",
        "INHABITANTS",
        "INSTRUMENTS",
        "INTELLIGENT",
        "INTERESTING",
        "INTERROGATE",
        "LOBOTOMIZED",
        "MAINTENANCE",
        "MANUFACTURE",
        "MASTERFULLY",
        "MISTRUSTFUL",
        "MISTRUSTING",
        "NIGHTVISION",
        "NONDESCRIPT",
        "NOTORIOUSLY",
        "OBSERVATION",
        "OPPORTUNITY",
        "OVERCROWDED",
        "OVERLOADING",
        "OVERLOOKING",
        "OVERPOWERED",
        "OVERWHELMED",
        "PARTITIONED",
        "PERMANENTLY",
        "PERPETRATOR",
        "PERSECUTION",
        "PERSONALITY",
        "POPULATIONS",
        "POSSIBILITY",
        "PRIESTESSES",
        "PROGRAMMING",
        "RADIOACTIVE",
        "RANGEFINDER",
        "RECOGNITION",
        "RECTANGULAR",
        "RELUCTANTLY",
        "REMEMBERING",
        "REPRIMANDED",
        "RESEARCHING",
        "RESIDENTIAL",
        "RESPONSIBLE",
        "SETTLEMENTS",
        "SHOPLIFTING",
        "SNAKEKEEPER",
        "STOREHOUSES",
        "SURROUNDING",
        "SURVIVALIST",
        "SYNTHESIZED",
        "TECHNICIANS",
        "TELEVISIONS",
        "TEMPERATURE",
        "TEMPORARILY",
        "THREATENING",
        "TRANSMITTER",
        "TREACHEROUS",
        "UNBEKNOWNST",
        "UNBELIEVERS",
        "UNCONSCIOUS",
        "UNDERGROUND",
        "UNDRINKABLE",
        "UNIMPORTANT",
        "UNORGANIZED",
        "UNSPEAKABLE",
        "UPHOLSTERED",
        "WHEREABOUTS",
        "WORSHIPPING"
    ],
    "12": [
        "ABOMINATIONS",
        "ACCOMPANYING",
        "ACCOMPLISHED",
        "AFTEREFFECTS",
        "AMPHITHEATER",
        "ANNOUNCEMENT",
        "ANTICIPATING",
        "APPRECIATION",
        "APPREHENSIVE",
        "ASSASSINATED",
        "AUTHENTICITY",
        "BRAINWASHING",
        "BROADCASTING",
        "CIRCUMSTANCE",
        "CIVILIZATION",
        "CONSPIRATORS",
        "CONSTRUCTION",
        "CONTAMINATED",
        "CONVERSATION",
        "DISAPPEARING",
        "ENCOUNTERING",
        "ENHANCEMENTS",
        "EVOLUTIONARY",
        "EXECUTIONERS",
        "EXTINGUISHER",
        "GOVERNMENTAL",
        "HEADQUARTERS",
        "IMMACULATELY",
        "IMPENETRABLE",
        "INACCESSIBLE",
        "INCREASINGLY",
        "INDEFINITELY",
        "INFILTRATION",
        "INITIATEHOOD",
        "INTELLIGENCE",
        "INTERROGATED",
        "INVESTIGATES",
        "LABORATORIES",
        "LOUDSPEAKERS",
        "MAGNETICALLY",
        "NORTHERNMOST",
        "OCCASIONALLY",
        "ORGANIZATION",
        "OVERWHELMING",
        "PARTICULARLY",
        "PARTNERSHIPS",
        "PUPPETMASTER",
        "PURIFICATION",
        "QUARTERSTAFF",
        "QUESTIONABLE",
        "RECUPERATING",
        "RELATIONSHIP",
        "REPLENISHING",
        "REPRIMANDING",
        "RESURRECTION",
        "SECLUSIONIST",
        "SPECIFICALLY",
        "SPOKESPERSON",
        "SUBTERRANEAN",
        "SUCCESSFULLY",
        "SURROUNDINGS",
        "SURVEILLANCE",
        "SURVIVALISTS",
        "SYMPATHIZERS",
        "TECHNOLOGIES",
        "THOUGHTFULLY",
        "TRANQUILLITY",
        "TRANSCRIBING",
        "TRANSMISSION",
        "TRANSMITTERS",
        "TRIUMPHANTLY",
        "UNAUTHORIZED",
        "WALKIETALKIE"
    ],
    "13": [
        "ACCESSIBILITY",
        "ACCOMPLISHING",
        "ADMINISTRATES",
        "ALTERNATIVELY",
        "AUTOMATICALLY",
        "CIRCUMFERENCE",
        "CIRCUMSTANCES",
        "COMMUNICATING",
        "CONSECUTIVELY",
        "CONTAMINATION",
        "CONTEMPLATION",
        "CORRESPONDING",
        "DISADVANTAGES",
        "DISAPPEARANCE",
        "ENTERTAINMENT",
        "GRANDDAUGHTER",
        "HALLUCINATION",
        "HIGHPRIESTESS",
        "INCRIMINATING",
        "INEXHAUSTIBLE",
        "INTELLECTUALS",
        "INTENTIONALLY",
        "INTERROGATING",
        "INTERROGATION",
        "INTERRUPTIONS",
        "INVESTIGATING",
        "KNOWLEDGEABLE",
        "METAMORPHOSIS",
        "MISCELLANEOUS",
        "MODIFICATIONS",
        "OVERPOPULATED",
        "PICKPOCKETING",
        "PREOCCUPATION",
        "PROGRESSIVELY",
        "RADIOACTIVITY",
        "SOPHISTICATED",
        "SUBSTANTIALLY",
        "SUPERSTITIOUS",
        "TECHNOLOGICAL",
        "THEORETICALLY",
        "THERMOGRAPHIC",
        "TRANSGRESSION",
        "UNDERSTANDING",
        "UNENCUMBERING",
        "UNFORTUNATELY",
        "UNPREDICTABLE"
    ],
    "14": [
        "ADMINISTRATION",
        "APOLOGETICALLY",
        "ASSASSINATIONS",
        "AUTHENTICATION",
        "COMMUNICATIONS",
        "DISAPPOINTMENT",
        "INTELLECTUALLY",
        "RECOMMENDATION",
        "SOPHISTICATION",
        "TELEVANGELISTS",
        "UNDERESTIMATED",
        "WHOLEHEARTEDLY"
    ],
    "15": [
        "CONDESCENDINGLY",
        "PERSONIFICATION",
        "UNCONSCIOUSNESS"
    ],
    "17": [
        "INDISTINGUISHABLE"
    ]
}
